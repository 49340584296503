// routes
import { PATHS } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";
import getRoles from "../../../utils/getRoles";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  about: <Icon icon="clarity:help-info-line" />,
  admin: icon("ic_kanban"),
  invoice: icon("ic_file"),
  transactions: icon("ic_invoice"),
};

const titles = {
  events: {
    en: "Home",
    ro: "Acasă",
  },
  becameOrganizer: {
    en: "Become an organizer",
    ro: "Devino organizator",
  },
  aboutUs: {
    en: "About us",
    ro: "Despre noi",
  },
  myBusiness: {
    en: "My Business",
    ro: "Afacerea mea",
  },
  myListings: {
    en: "My Listings",
    ro: "Postările mele",
  },
  dashboard: {
    en: "Dashboard",
    ro: "Panou Control",
  },
  addListing: {
    en: "Add New Listing",
    ro: "Creează o Postare",
  },
  user: {
    en: "User",
    ro: "Utilizator",
  },
  profile: {
    en: "Profile",
    ro: "Profil",
  },
  cart: {
    en: "Cart",
    ro: "Cos",
  },
  tickets: {
    en: "Tickets and Reservations",
    ro: "Bilete și Rezervări",
  },
  adminInterface: {
    en: "Admin interface",
    ro: "Interfaţă administrator",
  },
  invoices: {
    en: "Invoices",
    ro: "Facturi",
  },
  invoicesList: {
    en: "Invoices List",
    ro: "Lista Facturilor",
  },
  transactions: {
    en: "Transactions",
    ro: "Tranzacții",
  },
  invoiceCreate: {
    en: "Create Invoice",
    ro: "Creează o Factură",
  },
  companiesList: {
    en: "Companies List",
    ro: "Lista Companiilor",
  },
  platformSettings: {
    en: "Platform Settings",
    ro: "Setarile Platformei",
  },
};

/**
 * @param {string} userRole - user role
 * @param {string} language - language
 * @returns {object[]} sidebarConfig - sidebar config object depending on user role
 */
const getSidebarConfig = (userRole, language) => {
  return [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: "",
      items: [
        {
          title: titles.events[language],
          path: PATHS.listings.list,
          icon: ICONS.ecommerce,
        },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    ...(getRoles().Company.includes(userRole)
      ? [
          {
            subheader: "dashboard",
            items: [
              {
                title: titles.dashboard[language],
                path: PATHS.dashboard.root,
                icon: ICONS.analytics,
                children: [
                  {
                    title: titles.myListings[language],
                    path: PATHS.dashboard.myListings,
                  },
                  {
                    title: titles.addListing[language],
                    path: PATHS.dashboard.addEventWizard,
                  },
                ],
              },
            ],
          },
        ]
      : []),
    ...(getRoles().CompanyOnly.includes(userRole)
      ? [
          {
            subheader: "my business",
            items: [
              {
                title: titles.myBusiness[language],
                path: PATHS.myBusiness.root,
                icon: ICONS.analytics,
              },
            ],
          },
        ]
      : []),
    {
      subheader: "management",
      items: [
        {
          title: titles.user[language],
          path: PATHS.user.account,
          icon: ICONS.user,
          children: getRoles().UserOnly.includes(userRole)
            ? [
                {
                  title: titles.profile[language],
                  path: PATHS.user.account,
                },
                {
                  title: titles.cart[language],
                  path: PATHS.listings.checkout,
                },
                { title: titles.tickets[language], path: PATHS.user.tickets },
              ]
            : [
                {
                  title: titles.profile[language],
                  path: PATHS.user.account,
                },
              ],
        },
      ],
    },
    ...(getRoles().Company.includes(userRole)
      ? [
          {
            subheader: "Financiar",
            items: [
              {
                title: titles.transactions[language],
                path: PATHS.transactions.root,
                icon: ICONS.transactions,
              },
              {
                title: titles.invoices[language],
                path: PATHS.invoices.root,
                icon: ICONS.invoice,
                children: getRoles().Admin.includes(userRole)
                  ? [
                      {
                        title: titles.invoicesList[language],
                        path: PATHS.invoices.root,
                      },
                      {
                        title: titles.invoiceCreate[language],
                        path: PATHS.invoices.create,
                      },
                    ]
                  : [
                      {
                        title: titles.invoicesList[language],
                        path: PATHS.invoices.root,
                      },
                    ],
              },
            ],
          },
        ]
      : []),
    // ADMIN
    ...(getRoles().Admin.includes(userRole)
      ? [
          {
            subheader: "admin-interface",
            items: [
              {
                title: titles.adminInterface[language],
                path: PATHS.adminInterface.companiesList,
                icon: ICONS.admin,
                children: [
                  {
                    title: titles.companiesList[language],
                    path: PATHS.adminInterface.companiesList,
                  },
                  {
                    title: titles.platformSettings[language],
                    path: PATHS.adminInterface.platformSettings,
                  },
                ],
              },
            ],
          },
        ]
      : []),
  ];
};

export default getSidebarConfig;
