import React from "react";
import { Stack, Typography } from "@mui/material";
import GradeIcon from "@mui/icons-material/Grade";

const VenueRating = ({ rating, reviewsCount, other }) => {
  if (Number(reviewsCount) < 1) {
    return null;
  }

  return (
    <Stack direction="row" spacing={0.5} alignItems="center" {...other}>
      <Typography color="gray" fontSize={14}>
        {rating}
      </Typography>
      <GradeIcon fontSize="small" sx={(theme) => ({color:theme.palette.white})}/>
      {
        <Typography
          color="gray"
          fontSize={14}
        >{`(${reviewsCount})`}</Typography>
      }
    </Stack>
  );
};

export default VenueRating;
