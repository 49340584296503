import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
// components
import Image from "../../../../components/image";
import Lightbox from "../../../../components/lightbox";
import { IMAGES_CONSTANTS } from "../../../../config/constants/images-constants";
import useResponsive from "../../../../hooks/useResponsive";

// ----------------------------------------------------------------------

DetailsMainImage.propTypes = {
  images: PropTypes.array,
};

export default function DetailsMainImage({ images }) {
  const theme = useTheme();
  const [selectedImage, setSelectedImage] = useState(-1);
  const [imageHasApiCompression, setImageHasApiCompression] = useState(true);
  const isMobile = useResponsive("down", "sm");
  const coverImageSize = isMobile
    ? IMAGES_CONSTANTS.MAIN_IMAGE_EVENT_DETAILS_MOBILE
    : IMAGES_CONSTANTS.MAIN_IMAGE_EVENT_DETAILS_WEB;
  const mainImage = images[0];

  const imagesLightbox = images.map((img) => ({ src: img }));

  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  const renderLargeImg = (
    <Box
      sx={{ mb: 3, borderRadius: 2, overflow: "hidden", position: "relative" }}
    >
      <Image
        alt="product"
        key={
          imageHasApiCompression ? `${mainImage}@${coverImageSize}` : mainImage
        }
        src={
          imageHasApiCompression ? `${mainImage}@${coverImageSize}` : mainImage
        }
        ratio={IMAGES_CONSTANTS.LISTING_IMAGE_RATIO}
        onClick={() => handleOpenLightbox()}
        sx={{ cursor: "zoom-in" }}
        onError={() => {
          setImageHasApiCompression(false);
        }}
      />
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          "& .slick-slide": {
            float: theme.direction === "rtl" ? "right" : "left",
          },
        }}
      >
        {renderLargeImg}
      </Box>

      <Lightbox
        open={selectedImage >= 0}
        slides={imagesLightbox}
        close={handleCloseLightbox}
      />
    </>
  );
}
