import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/LoginPage"))
);
export const OAuthCompletedPage = Loadable(
  lazy(() => import("../pages/auth/OAuthCompletedPage"))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import("../pages/auth/ResetPasswordPage"))
);
export const NewPasswordPage = Loadable(
  lazy(() => import("../pages/auth/NewPasswordPage"))
);

export const RegisterPage = Loadable(
  lazy(() => import("../pages/auth/RegisterPage"))
);

export const VerifyCodePage = Loadable(
  lazy(() => import("../pages/auth/VerifyCodePage"))
);

export const PrivacyPolicy = Loadable(
  lazy(() => import("../components/policies/PrivacyPolicy"))
);
export const CookiesPolicy = Loadable(
  lazy(() => import("../components/policies/CookiesPolicy"))
);
export const TermsAndConditions = Loadable(
  lazy(() => import("../components/policies/TermsAndConditions"))
);
export const AuthGuardBackdrop = Loadable(
  lazy(() => import("../guards/AuthGuardBackdrop"))
);
export const RoleGuardBackdrop = Loadable(
  lazy(() => import("../guards/RoleBasedGuard"))
);
export const HomePage = Loadable(lazy(() => import("../pages/home/HomePage")));
export const Contact = Loadable(lazy(() => import("../pages/Contact")));
export const DownloadApp = Loadable(lazy(() => import("../pages/DownloadApp")));
export const MainLayout = Loadable(lazy(() => import("../layouts/main")));
export const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
export const UserTicketsPage = Loadable(
  lazy(() => import("../pages/dashboard/UserTicketsPage"))
);
export const UserEventTickets = Loadable(
  lazy(() => import("../pages/dashboard/UserEventTickets"))
);
export const EventManagerLayout = Loadable(
  lazy(() => import("../layouts/dashboard/eventManager/EventManagerLayout"))
);
export const EcommerceProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/eventManager/EcommerceProductCreate"))
);
export const AdminCompaniesListPage = Loadable(
  lazy(() => import("../pages/dashboard/adminInterface/AdminCompaniesListPage"))
);
export const AdminPlatformSettingsPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/adminInterface/AdminPlatformSettingsPage")
  )
);
export const AdminInvoicesPage = Loadable(
  lazy(() => import("../pages/dashboard/AdminInvoicesPage.js"))
);
export const AdminInvoiceDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/AdminInvoiceDetailsPage.js"))
);
export const AdminInvoiceCreate = Loadable(
  lazy(() => import("../pages/dashboard/AdminInvoiceCreate.js"))
);
export const AdminInvoicePreview = Loadable(
  lazy(() => import("../pages/dashboard/AdminInvoicePreview.js"))
);
export const TransactionsPage = Loadable(
  lazy(() => import("../pages/dashboard/TransactionsPage.js"))
);
export const AddEventWizard = Loadable(
  lazy(() =>
    import("../sections/dashboard/eventManager/events-wizard/AddEventWizard")
  )
);
export const RegisterCompanyPage = Loadable(
  lazy(() => import("../pages/company/register/RegisterPage"))
);
export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
export const PageResourceNotAvailable = Loadable(lazy(() => import("../pages/PageResourceNotAvailable")));
export const ListingsList = Loadable(
  lazy(() => import("../pages/listings/ListingsList"))
);
export const ListingsShowcase = Loadable(
  lazy(() => import("../pages/listings/ListingsShowcase"))
);
export const ListingDetails = Loadable(
  lazy(() => import("../pages/listings/ListingDetails"))
);
export const IframeListingDetails = Loadable(
  lazy(() => import("../pages/listings/IframeListingDetails"))
);
export const Checkout = Loadable(
  lazy(() => import("../pages/checkout/Checkout"))
);
export const RegisterForm = Loadable(
  lazy(() => import("../components/company/register/RegisterForm"))
);
export const CompanyLayout = Loadable(
  lazy(() => import("../layouts/dashboard/company/CompanyLayout"))
);
export const EcommerceProductListPage = Loadable(
  lazy(() => import("../pages/dashboard/eventManager/EcommerceProductListPage"))
);
