import PropTypes from "prop-types";
// @mui
import { Popover } from "@mui/material";
//
import getPosition from "./getPosition";
import { StyledArrow } from "./styles";
import { Box } from "@mui/system";

// ----------------------------------------------------------------------

MenuPopover.propTypes = {
  sx: PropTypes.object,
  open: PropTypes.object,
  children: PropTypes.node,
  disabledArrow: PropTypes.bool,
  closeOnMouseLeave: PropTypes.bool,
  arrow: PropTypes.oneOf([
    "top-left",
    "top-center",
    "top-right",
    "bottom-left",
    "bottom-center",
    "bottom-right",
    "left-top",
    "left-center",
    "left-bottom",
    "right-top",
    "right-center",
    "right-bottom",
  ]),
};

export default function MenuPopover({
  open,
  children,
  arrow = "top-right",
  disabledArrow,
  sx,
  closeOnMouseLeave,
  onClose,
  ...other
}) {
  const { style, anchorOrigin, transformOrigin } = getPosition(arrow);

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      slotProps={{
        paper: {
          sx: {
            p: 1,
            width: "auto",
            overflow: "visible",
            ...style,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20, flexShrink: 0 },
            },
            ...sx,
          },
        },
      }}
      {...other}
    >
      <Box onMouseLeave={closeOnMouseLeave ? () => onClose() : undefined}>
        {!disabledArrow && <StyledArrow arrow={arrow} />}

        {children}
      </Box>
    </Popover>
  );
}
