// @mui
import { Card, Skeleton, Stack } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

export default function SkeletonProductItem({ sx }) {
  const isMobile = useResponsive("down", "md");

  return (
    <Card sx={{ ...sx }}>
      <Skeleton variant="rectangular" sx={{ paddingTop: "100%", mb: 3 }} />
      <Stack spacing={2} sx={{ px: 2, pb: 2 }}>
        {/*<Skeleton variant="text" sx={{ width: 0.5 }} />*/}
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="start"
        >
          <Skeleton variant="text" sx={{ width: isMobile ? 50 : 100 }} />
          <Skeleton
            variant="text"
            sx={{ width: isMobile ? 30 : 70, height: 17 }}
          />
        </Stack>
      </Stack>
    </Card>
  );
}
