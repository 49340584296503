import PropTypes from "prop-types";
import { LazyMotion, m } from "framer-motion";
import { useLocation } from "react-router-dom";
import { PATHS } from "../../routes/paths";

// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions
const loadFeatures = () => import("./features.js").then((res) => res.default);

MotionLazyContainer.propTypes = {
  children: PropTypes.node,
};

export default function MotionLazyContainer({ children }) {
  const { pathname } = useLocation();
  return (
    <LazyMotion strict features={loadFeatures}>
      <m.div
        style={{ height: pathname !== PATHS.company.register ? "100%" : "" }}
      >
        {" "}
        {children}{" "}
      </m.div>
    </LazyMotion>
  );
}
