export const IMAGES_CONSTANTS = Object.freeze({
  SIZES: {
    SMALL: "200",
    MEDIUM: "500",
    LARGE: "1000",
  },
  CAROUSEL_SIZE_WEB: "500",
  THUMBNAIL_SIZE_WEB: "500",
  CAROUSEL_SIZE_MOBILE: "200",
  THUMBNAIL_SIZE_MOBILE: "500",
  LISTING_IMAGE_RATIO: "4/6",
  MAIN_IMAGE_EVENT_DETAILS_WEB: "1000",
  MAIN_IMAGE_EVENT_DETAILS_MOBILE: "500",
});
