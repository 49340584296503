import { Box, Grid } from "@mui/material";
import Chip from "./Chip";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../hooks/useResponsive";
import { useRef, useState } from "react";
import { useSettingsContext } from "./settings";

const MAX_GRID_HEIGHT = 300;
const DRAG_MULTIPLIER = 1;

export default function ChipSingleSelect({
  grid,
  items,
  value,
  onChange,
  selectedVariant,
  clickable,
  rowOnSmScreen,
  isInIframe = false,
  singleRow,
}) {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "sm");

  const rowOnSmScreenSx = {
    flexDirection: "row",
    justifyContent: "space-between",
    overflowX: "visible",
    overflowY: "hidden",
    gap: 2,
    flexWrap: "nowrap",
    px: 1,
    curor: "grab",
  };

  const containerRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const isMobile = useResponsive("down", "sm");

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.clientX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.clientX - containerRef.current.offsetLeft;
    const walk = (x - startX) * DRAG_MULTIPLIER; // Adjust the multiplier for smoother scrolling
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const getJustifyContent = (index) => {
    const remainder = index % 3;

    if (remainder === 0) {
      return "flex-end";
    } else if (remainder === 1) {
      return "center";
    } else {
      return "flex-start";
    }
  };

  const isObject = (item) => {
    return typeof item === "object" && item !== null;
  };

  const renderChip = (item, index) => {
    const chipLabel = isObject(item) ? item.label : item;
    const chipValue = isObject(item) ? item.value : item;

    return (
      <Chip
        sx={{
          fontSize: theme.typography.h6.fontSize,
          paddingY: 3,
          paddingX: 1,
          marginTop: isMobile ? 0 : 1,
          marginRight: !grid && index !== items.length - 1 ? 1 : 0,
          lineHeight: theme.typography.pxToRem(26),
          backgroundColor: isInIframe ? 
            chipValue === value ? 
                `${selectedVariant || theme.palette.text.primary} !important` :
                `${theme.palette.background.neutral} !important`
            : undefined,
          color: isInIframe ?
            chipValue === value ?
              theme.palette.background.default :
              theme.palette.text.primary
            : undefined,
        }}
        clickable={clickable ?? true}
        color={
          isInIframe ? 
            undefined
            : chipValue === value ? selectedVariant || "light" : "default"
        }
        id={index}
        label={chipLabel}
        onClick={() => {
          onChange(chipValue);
        }}
        key={index}
      />
    );
  };

  const containerProps = {
    ref: containerRef,
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
  };

  if (grid) {
    return (
      <Grid
        container
        columns={3}
        rowSpacing={2}
        sx={{
          paddingX: isDesktop ? 6 : 0,
          maxHeight: MAX_GRID_HEIGHT,
          overflowY: "scroll",
          ...(rowOnSmScreen && { ...rowOnSmScreenSx }),
        }}
        {...containerProps}
      >
        {items.map((item, index) => (
          <Grid
            item
            component={"span"}
            xs={1}
            sx={{
              display: "flex",
              justifyContent: getJustifyContent(index),
            }}
            key={index}
          >
            {renderChip(item, index)}
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Box display="flex" flexWrap="wrap"
         sx={{
           ...(singleRow && {
             ...{
               flexDirection: "row",
               overflowX: "visible",
               overflowY: "hidden",
               flexWrap: "nowrap",
               px: 1,
             },
           }),
         }}>
      {items.map((item, index) => renderChip(item, index))}
    </Box>
  );
}
