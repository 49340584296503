import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const PrimaryButton = styled(Button)(({ theme }) => {
  return {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.common.black
        : theme.palette.common.white,
    color:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.common.black,
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.common.black
          : theme.palette.common.white,
      boxShadow: "none",
    },
  };
});

const SecondaryButton = styled(Button)(({ theme }) => {
  return {
    border: `1px solid ${
      theme.palette.mode === "light"
        ? theme.palette.common.black
        : theme.palette.common.white
    }`,
    "&.Mui-disabled": {
      border: "none",
    },
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.common.black,
    color:
      theme.palette.mode === "light"
        ? theme.palette.common.black
        : theme.palette.common.white,
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.common.white
          : theme.palette.common.black,
      boxShadow: "none",
    },
  };
});

const IncrementorButton = styled(IconButton)(({ theme }) => {
  return {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.common.black
        : theme.palette.common.white,
    color:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.common.black,
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.common.black
          : theme.palette.common.white,
      color:
        theme.palette.mode === "light"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
  };
});

export { SecondaryButton, PrimaryButton, IncrementorButton };
