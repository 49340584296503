export const TICKET_CONSTANTS = Object.freeze({
  TVA_COMMISSION: 9,
  DITL_COMMISSION: 2,
  NUMBER_OF_DECIMALS: 2,
  FIXED_PRECISION: 100,
  DELETE_MODULE_ALLOWED_STATE: "ALLOWED",
  DELETE_MODULE_FORBIDDEN_STATE: "FORBIDDEN",
  DELETE_MODULE_CLOSED_STATE: "CLOSED",
  STANDARD_EVENT_COMMISSION: 7,
  MAX_CART_QUANTITY: 10,
  DOCUMENT_TYPE: {
    TICKETS: 1,
    FILTERED_TICKETS: 2,
  },
});
