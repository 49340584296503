import PropTypes from "prop-types";
// @mui
import { Box } from "@mui/material";
// components
import { SkeletonProductItem } from "../../../../components/skeleton";
//
import ShopEventCard from "./ShopEventCard";
import ShopVenueCard from "./ShopVenueCard";

// ----------------------------------------------------------------------

ShopListingsList.propTypes = {
  products: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

const getLowestPrice = (product) => {
  const tickets = product.ticketTypes;
  const prices = tickets.map((ticket) => ticket.price);
  return Math.min(...prices);
};

const mapProducts = (product) => {
  if (product.type === "event")
    return (
      <ShopEventCard
        key={product.id}
        name={product.name}
        price={getLowestPrice(product)}
        cover={product.images[0]}
        id={product.id}
        type={product.type}
        entryType={product.entryType}
      />
    );

  if (product.type === "venue")
    return (
      <ShopVenueCard
        rating={product.rating}
        reviewsCount={product.reviewsCount}
        key={product.id}
        name={product.name}
        cover={product.images[0]}
        id={product.id}
        type={product.type}
      />
    );
};

export default function ShopListingsList({ products, loading }) {
  return (
    <Box
      sx={{
        display: "grid",
        gap: 3,
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
        },
      }}
    >
      {loading
        ? [...Array(12)].map((_, index) => <SkeletonProductItem key={index} />)
        : products.map(mapProducts)}
    </Box>
  );
}
