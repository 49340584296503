import { Navigate, useRoutes } from "react-router-dom";
// auth
import GuestGuard from "../guards/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  DownloadApp,
  AuthGuardBackdrop,
  Contact,
  CookiesPolicy,
  HomePage,
  LoginPage,
  OAuthCompletedPage,
  MainLayout,
  Page404,
  ResetPasswordPage,
  NewPasswordPage,
  RegisterPage,
  TermsAndConditions,
  PrivacyPolicy,
  UserAccount,
  VerifyCodePage,
  EventManagerLayout,
  EcommerceProductCreate,
  AddEventWizard,
  RegisterCompanyPage,
  CompanyLayout,
  RegisterForm,
  Checkout,
  ListingDetails,
  UserTicketsPage,
  EcommerceProductListPage,
  AdminCompaniesListPage,
  ListingsShowcase,
  UserEventTickets,
  AdminPlatformSettingsPage,
  IframeListingDetails,
  AdminInvoicesPage,
  AdminInvoiceDetailsPage,
  AdminInvoiceCreate,
  AdminInvoicePreview,
  TransactionsPage,
  PageResourceNotAvailable,
} from "./elements";
import CheckoutSuccess from "src/sections/dashboard/checkout/CheckoutSuccess";
import CheckoutFailed from "src/sections/dashboard/checkout/CheckoutFailed";
import { PATHS } from "./paths";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import getRoles from "../utils/getRoles";
import BookingDetails from "../pages/listings/BookingDetails";
import BookingPage from "../sections/dashboard/events/booking/BookingPage";
import BookingWizard from "../sections/dashboard/events/bookingWizard/BookingWizard";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/auth",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: "oauth-completed",
          element: (
            <GuestGuard>
              <OAuthCompletedPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            {
              path: "verify",
              element: (
                <GuestGuard>
                  <VerifyCodePage />
                </GuestGuard>
              ),
            },
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "contact-us", element: <Contact /> },
        { path: "download-app", element: <DownloadApp /> },
        { path: "terms-and-conditions", element: <TermsAndConditions /> },
        { path: "cookies-policy", element: <CookiesPolicy /> },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
      ],
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "terms-and-conditions", element: <TermsAndConditions /> },
        { path: "cookies-policy", element: <CookiesPolicy /> },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            {
              path: "account",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.auxiliary.privacyPolicy}>
                  <UserAccount />
                </AuthGuardBackdrop>
              ),
            },
            {
              path: "tickets",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.auxiliary.privacyPolicy}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().UserOnly}>
                    <UserTicketsPage />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
            {
              path: "tickets/:eventId/:eventName",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.auxiliary.privacyPolicy}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().UserOnly}>
                    <UserEventTickets />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
          ],
        },
        {
          path: "my-business",
          children: [
            {
              path: "business-details",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard
                    hasContent={true}
                    roles={getRoles().CompanyOnly}
                  >
                    <CompanyLayout />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
              children: [{ path: "", element: <RegisterForm /> }],
            },
          ],
        },
        {
          path: "dashboard",
          element: (
            <AuthGuardBackdrop onReturn={PATHS.listings.list}>
              <RoleBasedGuard hasContent={true} roles={getRoles().Company}>
                <EventManagerLayout />
              </RoleBasedGuard>
            </AuthGuardBackdrop>
          ),
          children: [
            { path: "wizard-event", element: <AddEventWizard /> },
            {
              path: "my-listings",
              children: [
                { path: "", element: <EcommerceProductListPage /> },
                {
                  path: "event-editor/:id",
                  element: <EcommerceProductCreate />,
                },
              ],
            },
          ],
        },
        {
          path: "admin-interface",
          children: [
            {
              path: "platform-settings",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().Admin}>
                    <AdminPlatformSettingsPage />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
            {
              path: "companies-list",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().Admin}>
                    <AdminCompaniesListPage />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
            {
              path: "companies-list/company/details/:id",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().Admin}>
                    <CompanyLayout />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
              children: [{ path: "", element: <RegisterForm /> }],
            },
          ],
        },
        {
          path: "invoices",
          children: [
            {
              index: true,
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().Company}>
                    <AdminInvoicesPage />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
            {
              path: "invoice/:packageId/:invoiceId",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().Company}>
                    <AdminInvoiceDetailsPage />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
            {
              path: "preview",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().Admin}>
                    <AdminInvoicePreview />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
            {
              path: "invoice/create",
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().Company}>
                    <AdminInvoiceCreate />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
          ],
        },
        {
          path: "transactions",
          children: [
            {
              index: true,
              element: (
                <AuthGuardBackdrop onReturn={PATHS.listings.list}>
                  <RoleBasedGuard hasContent={true} roles={getRoles().Company}>
                    <TransactionsPage />
                  </RoleBasedGuard>
                </AuthGuardBackdrop>
              ),
            },
          ],
        },
        {
          path: "booking",
          children: [
            {
              element: <Navigate to={PATHS.listings.list} replace />,
              index: true,
            },
            {
              path: "new/:eventId",
              element: <BookingPage />,
            },
            {
              path: "newWizard/:eventId",
              element: <BookingWizard />,
            },
            {
              path: "details/:bookingId",
              element: <BookingDetails />,
            },
          ],
        },
        {
          path: "listings",
          children: [
            {
              element: <Navigate to={PATHS.listings.list} replace />,
              index: true,
            },
            { path: "list", element: <ListingsShowcase /> },
            {
              path: "details/:subcategory/:name/:id",
              element: <ListingDetails />,
            },
            {
              path: "checkout",
              element: (
                <RoleBasedGuard hasContent={true} roles={getRoles().People}>
                  <Checkout />
                </RoleBasedGuard>
              ),
            },
            { path: "success", element: <CheckoutSuccess /> },
            { path: "fail", element: <CheckoutFailed /> },
          ],
        },
      ],
    },
    {
      path: "/listings/iframe/details/:subcategory/:name/:id",
      element: <IframeListingDetails />,
    },
    {
      path: "/company",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "register",
          element: (
            <AuthGuardBackdrop onReturn={PATHS.auxiliary.privacyPolicy}>
              <RegisterCompanyPage />
            </AuthGuardBackdrop>
          ),
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "resourceNotAvailable", element: <PageResourceNotAvailable /> },
      ],
    },
    // { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
