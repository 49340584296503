import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useState,
} from "react";
// utils
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { isValidToken, setSession } from "./utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER } from "../graphql/queries";
import { Account, accountFactory } from "../classes/Account";
import LoadingScreen from "../components/loading-screen";
import { GET_EVENTS } from "../graphql/queries/events";
import { useApolloClient } from "@apollo/client";
import { GET_VENUES } from "../graphql/queries/venues";
import { LOGIN_SHADOW_ACCOUNT } from "../graphql/mutations";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  isShadow: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isShadow: action.payload.isShadow,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      isShadow: false,
      user: action.payload.user,
    };
  }
  if (action.type === "REFRESH") {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: true,
      isShadow: false,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      isShadow: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loadingApp, setLoadingApp] = useState(true);
  const client = useApolloClient();
  const [loginShadowAccount] = useMutation(LOGIN_SHADOW_ACCOUNT);

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
  });

  const storageAvailable = localStorageAvailable();

  const getShadowAccount = async () => {
    const res = await loginShadowAccount();
    const accessToken = res?.data?.shadowLogin ?? "";

    setSession(accessToken);

    const userShadow = await getUser({
      context: { headers: { Authorization: accessToken } },
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
      refetchQueries: [GET_EVENTS, GET_VENUES],
    });

    const user = accountFactory(userShadow.data.me);
    return user;
  };

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";

      if (accessToken && isValidToken(accessToken)) {
        // real user is logged in: user, company or admin
        setSession(accessToken);

        const res = await getUser({
          context: { headers: { Authorization: accessToken } },
          fetchPolicy: "network-only", // Doesn't check cache before making a network request
          refetchQueries: [GET_EVENTS, GET_VENUES],
        });

        const user = accountFactory(res.data.me);

        setLoadingApp(false);

        if (user.accountType === "PERSISTED") {
          dispatch({
            type: "INITIAL",
            payload: {
              isAuthenticated: true,
              isShadow: false,
              user,
            },
          });
        } else if (user.accountType === "SHADOW") {
          dispatch({
            type: "INITIAL",
            payload: {
              isAuthenticated: false,
              isShadow: true,
              user,
            },
          });
        }
      } else {
        // no user is logged in, so we create a shadowAccount and associate a JWT

        const user = await getShadowAccount();

        setLoadingApp(false);

        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            isShadow: true,
            user: user,
          },
        });
      }
    } catch (error) {
      const user = await getShadowAccount();

      setLoadingApp(false);
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          isShadow: true,
          user: true,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const getNewShadow = useCallback(async () => {
    try {
      const user = await getShadowAccount();

      setLoadingApp(false);

      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          isShadow: true,
          user: user,
        },
      });
    } catch (error) {
      const user = await getShadowAccount();

      setLoadingApp(false);
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          isShadow: true,
          user: true,
        },
      });
    }
  }, []);

  // LOGIN
  const login = useCallback(async (accessToken, user) => {
    setSession(accessToken);

    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (accessToken, user) => {
    localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setLoadingApp(true);

    await client.resetStore();
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });

    // after logging out, we have to initialize a shadowAccount
    const user = await getShadowAccount();

    setLoadingApp(false);

    dispatch({
      type: "INITIAL",
      payload: {
        isAuthenticated: false,
        isShadow: true,
        user: user,
      },
    });

    setLoadingApp(false);
  }, []);

  const refresh = async () => {
    const accessToken = localStorage.getItem("accessToken");

    const res = await getUser({
      context: { headers: { Authorization: accessToken } },
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
    });

    const user = accountFactory(res.data.me);

    dispatch({
      type: "REFRESH",
      payload: {
        user,
      },
    });
  };

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isShadow: state.isShadow,
      user: state.user,
      method: "jwt",
      refresh,
      login,
      register,
      logout,
      getNewShadow,
    }),
    [
      state.isAuthenticated,
      state.isShadow,
      state.isInitialized,
      state.user,
      refresh,
      login,
      logout,
      register,
      getNewShadow,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {!loadingApp ? children : <LoadingScreen />}
    </AuthContext.Provider>
  );
}
