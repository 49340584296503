import PropTypes from "prop-types";
// @mui
import { useTheme } from "@mui/material/styles";
import { AppBar, Fade, Grid, IconButton, Stack, Toolbar } from "@mui/material";
// utils
import { bgBlur, bgGradient } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config-global";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
//
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import { useLocation } from "react-router-dom";
import { useScrollDirection } from "../../../hooks/useScrollDirection";
import DesktopFilters from "../../../components/listings-filters/desktopFilters";
import getRoles from "../../../utils/getRoles";
import { useAuthContext } from "../../../hooks/useAuthContext";

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({
  onOpenNav,
  displayLogo,
  themeLayout,
  countyOptions,
  handleChangeCounty,
  county,
  categoryValue,
  handleChangeSwitch,
  categoryOptions,
}) {
  const theme = useTheme();

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const location = useLocation().pathname.split("/");

  const isDesktop = useResponsive("up", "lg");

  const isMd = useResponsive("up", "md"); // used for filters only on listings page

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP); //&& !isNavHorizontal;

  const scrollDirection = useScrollDirection();

  const { user } = useAuthContext();

  // it colors the background of the header if it's mobile
  const headerBackground =
    location[1] === "listings"
      ? isOffset === false || scrollDirection === "down"
        ? {
            ...bgGradient({
              startColor: `${isNavHorizontal ? "rgb(0,0,0,60%)" : "transpar"}`,
              endColor: "transparent",
            }),
            backdropFilter: "none",
            WebkitBackdropFilter: "none",
            backgroundColor: "none",
            transition: "background 0.3s",
          }
        : {
            ...bgBlur({
              color: theme.palette.background.default,
              blur: 8,
              opacity: 0.7,
            }),
          }
      : bgBlur({
          color: theme.palette.background.default,
          blur: 8,
          opacity: 0.7,
        });

  const renderContent = (
    <>
      <Grid
        container
        columns={{ xs: 24, md: 24 }}
        sx={{
          mt: "4px",
        }}
      >
        <Grid item xs={3}>
          {isNavHorizontal && <Logo />}
          {!isDesktop &&
            location[1] !== "listings" &&
            getRoles().Company.includes(user.role) && (
              <IconButton
                onClick={onOpenNav}
                sx={{ mr: 1, color: "text.primary" }}
              >
                <Iconify icon="eva:menu-2-fill" />
              </IconButton>
            )}
        </Grid>

        <Grid item xs={18}>
          {isMd && location[1] === "listings" && location[2] === "list" ? (
            <DesktopFilters
              countyOptions={countyOptions}
              handleChangeCounty={handleChangeCounty}
              county={county}
              categoryValue={categoryValue}
              handleChangeSwitch={handleChangeSwitch}
              categoryOptions={categoryOptions}
            />
          ) : null}
        </Grid>

        <Grid item xs={3}>
          <Stack
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <LanguagePopover />

            {/*<NotificationsPopover />*/}

            {/*<ContactsPopover />*/}

            <AccountPopover />
          </Stack>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Fade in={scrollDirection === "up"} timeout={{ enter: 200, exit: 650 }}>
      <AppBar
        sx={{
          boxShadow: "none",
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          backgroundColor: "transparent",
          ...(isDesktop && {
            width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            // ...(isOffset && {
            //   height: HEADER.H_DASHBOARD_HIDE,
            // }),
            ...(isNavHorizontal && {
              width: 1,
              // bgcolor: "background.default",
              // height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
              // borderBottom: `dashed 1px ${theme.palette.divider}`,
            }),
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
            }),
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
            ...headerBackground,
          }}
        >
          {renderContent}
        </Toolbar>
      </AppBar>
    </Fade>
  );
}
