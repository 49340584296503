function EmailIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33594 2.66602H12.6693C13.7738 2.66602 14.6693 3.56145 14.6693 4.66602V11.3327C14.6693 12.4373 13.7738 13.3327 12.6693 13.3327H3.33594C2.23137 13.3327 1.33594 12.4373 1.33594 11.3327V4.66602C1.33594 3.56145 2.23137 2.66602 3.33594 2.66602ZM8.33594 6.97935L12.6693 3.99935H3.33594L7.66927 6.97935C7.87554 7.09844 8.12967 7.09844 8.33594 6.97935Z"
        fill="white"
      />
    </svg>
  );
}

export default EmailIcon;
