export const EVENT_TYPES_CONSTANTS = Object.freeze({
  TYPES: {
    TICKETS_ONLY: 2,
    TICKETS_OR_BOOKINGS_NO_HF: 3,
    TICKETS_OR_BOOKINGS_AND_HF: 4,
    VENUE_NO_HF: 7,
    VENUE_AND_HF: 8,
    EXPERIENCE_NO_BOOKINGS: 9,
    EXPERIENCE_AND_BOOKINGS: 10,
  },
  COMBINATIONS: {
    NO_OPENING_HOURS_AND_NON_PERMANENT: [2, 3, 4],
    OPENING_HOURS_AND_PERMANENT: [7, 8, 9, 10],
  },
});
