import PropTypes from "prop-types";
import { Navigate, useSearchParams } from "react-router-dom";
// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthContext } from "../hooks/useAuthContext";
import { PATHS } from "../routes/paths";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const [searchParams] = useSearchParams();

  if (isAuthenticated) {
    let redirectPath = PATHS.listings.list;
    if (searchParams.get("redirectPath")) {
      redirectPath = searchParams.get("redirectPath");
    }
    return <Navigate to={redirectPath} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
