import { META_TAGS } from "./constants/meta-helmet-tags-constants";

export const metaHelmetTagsGeneral = [
  <meta
    property={META_TAGS.TW_CARD}
    content={META_TAGS.TW_CARD_VALUE}
    key={META_TAGS.TW_CARD}
  />,
  <meta
    property={META_TAGS.TW_SITE}
    content={META_TAGS.TW_SITE_VALUE}
    key={META_TAGS.TW_SITE}
  />,
  <meta
    property={META_TAGS.OG_SITE_NAME}
    content={META_TAGS.SITE_NAME_VALUE}
    key={META_TAGS.OG_SITE_NAME}
  />,
  <meta
    property={META_TAGS.AL_IOS_APP_NAME}
    content={META_TAGS.IOS_APP_NAME_VALUE}
    key={META_TAGS.AL_IOS_APP_NAME}
  />,
  <meta
    property={META_TAGS.AL_IOS_APP_STORE_ID}
    content={META_TAGS.IOS_APP_STORE_ID_VALUE}
    key={META_TAGS.AL_IOS_APP_STORE_ID}
  />,
  <meta
    property={META_TAGS.AL_IOS_URL}
    content={META_TAGS.IOS_URL_VALUE}
    key={META_TAGS.AL_IOS_URL}
  />,
  <meta
    property={META_TAGS.TW_APP_NAME_IPHONE}
    content={META_TAGS.IOS_APP_NAME_VALUE}
    key={META_TAGS.TW_APP_NAME_IPHONE}
  />,
  <meta
    property={META_TAGS.TW_APP_ID_IPHONE}
    content={META_TAGS.IOS_APP_STORE_ID_VALUE}
    key={META_TAGS.TW_APP_ID_IPHONE}
  />,
  <meta
    property={META_TAGS.TW_APP_URL_IPHONE}
    content={META_TAGS.IOS_URL_VALUE}
    key={META_TAGS.TW_APP_URL_IPHONE}
  />,
  <meta
    property={META_TAGS.TW_APP_NAME_GOOGLE}
    content={META_TAGS.SITE_NAME_VALUE}
    key={META_TAGS.TW_APP_NAME_GOOGLE}
  />,
  <meta
    property={META_TAGS.TW_APP_ID_GOOGLE}
    content={META_TAGS.SITE_NAME_VALUE}
    key={META_TAGS.TW_APP_ID_GOOGLE}
  />,
  <meta
    property={META_TAGS.TW_APP_URL_GOOGLE}
    content={META_TAGS.TW_APP_URL_GOOGLE_VALUE}
    key={META_TAGS.TW_APP_URL_GOOGLE}
  />,
];
