import { gql } from "@apollo/client";

export const SET_BOOKING_STATUS = gql`
  mutation SetStatusBookingReservation(
    $bookingSessionId: String!
    $status: BookingStatus!
  ) {
    setStatusBookingReservation(
      bookingSessionId: $bookingSessionId
      status: $status
    )
  }
`;

export const CREATE_BOOKING_USER = gql`
  mutation CreateBookingReservation(
    $bookedDate: DateTime
    $bookedHour: String
    $numberOfGuests: Int!
    $duration: Int
    $bookingModuleId: String!
    $areaId: String
  ) {
    createBookingReservation(
      bookedDate: $bookedDate
      bookedHour: $bookedHour
      numberOfGuests: $numberOfGuests
      duration: $duration
      bookingModuleId: $bookingModuleId
      areaId: $areaId
    )
  }
`;
export const CREATE_BOOKING_HOLDING_FEE_USER = gql`
  mutation CreateCartBookingHoldingFee(
    $metadata: CartBookingHoldingFee
    $quantity: Int
    $ticketId: ID
  ) {
    createCartBookingHoldingFee(
      metadata: $metadata
      quantity: $quantity
      ticketId: $ticketId
    ) {
      id
      items {
        id
        metadata
        quantity
        seatIds
        seatsHoldToken
      }
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation CreateBookingReservation(
    $bookedDate: DateTime!
    $bookedHour: String!
    $numberOfGuests: Int!
    $duration: Int!
    $bookingModuleId: String!
    $companyAdded: ReservationCompanyAdded
    $areaId: String
    $tableId: [String]
  ) {
    createBookingReservation(
      bookedDate: $bookedDate
      bookedHour: $bookedHour
      numberOfGuests: $numberOfGuests
      duration: $duration
      bookingModuleId: $bookingModuleId
      companyAdded: $companyAdded
      areaId: $areaId
      tableId: $tableId
    )
  }
`;

export const RESCHEDULE_BOOKING = gql`
  mutation RescheduleBookingReservation(
    $sessionId: String!
    $bookedDate: DateTime!
    $bookedHour: String!
    $numberOfGuests: Int!
    $duration: Int!
    $bookingModuleId: String!
    $tableId: [String]
    $areaId: String
  ) {
    rescheduleBookingReservation(
      sessionId: $sessionId
      bookedDate: $bookedDate
      bookedHour: $bookedHour
      numberOfGuests: $numberOfGuests
      duration: $duration
      bookingModuleId: $bookingModuleId
      tableId: $tableId
      areaId: $areaId
    )
  }
`;

export const BLOCK_BOOKING_INTERVAL = gql`
  mutation BlockTablesBookingReservation(
    $bookingModuleId: String!
    $areaId: String!
    $bookedDate: DateTime!
    $startTime: String!
    $endTime: String!
    $tables: [String!]
  ) {
    blockTablesBookingReservation(
      bookingModuleId: $bookingModuleId
      areaId: $areaId
      bookedDate: $bookedDate
      startTime: $startTime
      endTime: $endTime
      tables: $tables
    )
  }
`;

export const CHECK_AVAILABILITY_BOOKING = gql`
  query CheckAvailabilityBookingmodule(
    $bookingModuleId: ID!
    $lookupYm: String
    $numberOfGuests: Int
    $bookedDate: String
    $areaId: String
    $bookedHour: String
    $duration: Int
    $showFirstAvailable: Boolean
  ) {
    checkAvailabilityBookingmodule(
      bookingModuleId: $bookingModuleId
      lookupYM: $lookupYm
      numberOfGuests: $numberOfGuests
      bookedDate: $bookedDate
      areaId: $areaId
      bookedHour: $bookedHour
      duration: $duration
      showFirstAvailable: $showFirstAvailable
    ) {
      dates {
        name
        status
      }
      bookedHours
      bookingModuleType
      durations
      areas {
        id
        name
        minHoldingFee
      }
      tables {
        id
        name
        capacity
        areaId
        status
      }
      holdingFee
    }
  }
`;
