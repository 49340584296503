import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, Stack, Typography } from "@mui/material";
// routes
import { PATHS } from "../../../../routes/paths";
// utils
// components
import Image from "../../../../components/image/Image";
import VenueRating from "../../../../components/VenueRating";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { IMAGES_CONSTANTS } from "../../../../config/constants/images-constants";
import useResponsive from "../../../../hooks/useResponsive";

// ----------------------------------------------------------------------

ShopVenueCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  cover: PropTypes.string,
  type: PropTypes.string,
  rating: PropTypes.number,
  reviewsCount: PropTypes.number,
  sx: PropTypes.object,
};

export default function ShopVenueCard({
  name,
  cover,
  id,
  subcategory,
  rating,
  reviewsCount,
  sx,
}) {
  const isSmallScreen = useResponsive("down", "md");
  const isAboveLg = useResponsive("up", "lg");
  const isBetweenSmAndMd = useResponsive("between", "sm", "md");

  const linkTo = PATHS.listings.view(
    paramCase(subcategory),
    paramCase(name),
    paramCase(id)
  );

  const [imageHasApiCompression, setImageHasApiCompression] = useState(true);
  const [coverImageSize, setCoverImageSize] = useState(
    IMAGES_CONSTANTS.CAROUSEL_SIZE_MOBILE
  );

  useEffect(() => {
    if (isBetweenSmAndMd || isAboveLg) {
      setCoverImageSize(IMAGES_CONSTANTS.SIZES.MEDIUM);
    }
  }, [isBetweenSmAndMd, isAboveLg]);

  const handleClick = () => {
    try {
      ReactGA.event("select_item", {
        items: [
          {
            item_id: id,
            item_name: name,
            item_category: "venue",
          },
        ],
      });
    } catch (e) {}
  };

  return (
    <Link
      color="inherit"
      to={linkTo}
      component={RouterLink}
      onClick={handleClick}
    >
      <Stack sx={sx || {}} direction="column">
        <Box sx={{ position: "relative" }}>
          <Image
            alt={name}
            key={imageHasApiCompression ? `${cover}@${coverImageSize}` : cover}
            src={imageHasApiCompression ? `${cover}@${coverImageSize}` : cover}
            ratio={IMAGES_CONSTANTS.LISTING_IMAGE_RATIO}
            sx={{ borderRadius: 1 }}
            onError={() => {
              setImageHasApiCompression(false);
            }}
          />
        </Box>

        {!isSmallScreen && (
          <Stack spacing={0} sx={{ pt: 1 }}>
            <Typography variant="h6">{name}</Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <VenueRating rating={rating} reviewsCount={reviewsCount} />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Link>
  );
}
