import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";
import {
  numberKeyDownInputFilter,
  numberOnChangeInputFilter,
} from "src/utils/rhfUtils";
import { GENERAL_CONSTANTS } from "src/config/constants/general-constants";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFTextField({
  name,
  helperText,
  type,
  noNegativeNumbers,
  number0IsEmpty,
  onChange,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={
            typeof field.value === "number" &&
            number0IsEmpty &&
            field.value === 0
              ? ""
              : field.value
          }
          onKeyDown={(event) => {
            if (type === "number") {
              numberKeyDownInputFilter(
                noNegativeNumbers
                  ? GENERAL_CONSTANTS.INPUT_NUMBER_INVALID_CHARACTERS
                  : GENERAL_CONSTANTS.INPUT_NUMBER_INVALID_CHARACTERS_ALLOW_MINUS,
                event
              );
            }
          }}
          onChange={(event) => {
            let newValue = event.target.value;

            if (type === "number") {
              newValue = numberOnChangeInputFilter(newValue);

              if (noNegativeNumbers && newValue < 0) {
                newValue = 0;
              }

              field.onChange({
                target: {
                  value: newValue,
                },
              });
            } else {
              field.onChange(event);
            }
            onChange?.(event);
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          type={type}
          {...other}
        />
      )}
    />
  );
}
