import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Stack,
  Grid,
  Avatar,
  Typography,
} from "@mui/material";
import { useSettingsContext } from "../../components/settings";
import Page from "../../components/Page";
import CartWidget from "../../sections/dashboard/events/CartWidget";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocales } from "../../locales";
import Iconify from "../../components/iconify";
import getRoles from "src/utils/getRoles";
import SocialsButton from "../../components/SocialsButton";
import { PATHS } from "../../routes/paths";
import { comparePathNames } from "../../utils/comparePathNames";
import { GENERAL_CONSTANTS } from "../../config/constants/general-constants";

const FOOTER_HEIGHT = 350;

function EventImageLayout({
  title,
  withBackButton,
  meta,
  image,
  organizerDisplayName,
  fields,
  organizerAvatar,
  children,
  isInFrame = false,
  useFullHeight = false,
}) {
  const { user } = useAuthContext();
  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const location = useLocation();
  const { isAuthenticated } = useAuthContext();

  return (
    <Page
      title={title}
      meta={meta}
      sx={{
        minHeight: `calc(100vh - ${FOOTER_HEIGHT}px)`,
      }}
    >
      {withBackButton && (
        <Button
          sx={{
            color: "white",
            zIndex: 999,
            position: "absolute",
            top: 30,
            left: "5%",
          }}
          variant="text"
          size="medium"
          onClick={() => {
            if (
              comparePathNames(location.pathname, PATHS.booking.details(), 1)
            ) {
              if (isAuthenticated === true) {
                navigate(PATHS.user.tickets);
              } else {
                navigate(PATHS.listings.list);
              }
            } else {
              navigate(PATHS.listings.list);
            }
          }}
        >
          <Iconify icon="material-symbols:chevron-left" width={24} />
          {translate("productPage.back")}
        </Button>
      )}
      <Container maxWidth={themeStretch ? false : "mediumLarge"}>
        {!isInFrame && getRoles().People.includes(user.role) && <CartWidget />}
        <img
          alt="background"
          src={image}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: !useFullHeight ? 450 : "100%",
            filter: `blur(30px) brightness(40%)`,
            objectFit: "cover",
            pointerEvents: "none",
          }}
        />
        {children}
        {organizerDisplayName && (
          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={12} md={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {organizerAvatar ? (
                  <Avatar sx={{ mr: 2 }} src={organizerAvatar} />
                ) : (
                  <Avatar sx={{ mr: 2 }}>{organizerDisplayName[0]}</Avatar>
                )}
                <div>
                  <Typography variant="subtitle1">
                    {organizerDisplayName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {translate("productPage.organizer")}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {fields && (
          <Stack alignItems="start" sx={{ mt: 1 }}>
            <SocialsButton initialColor links={fields} />
          </Stack>
        )}
      </Container>
    </Page>
  );
}

export default EventImageLayout;
