// @mui
import { Box, Stack } from "@mui/material";
// config
import { NAV } from "../../../config-global";
// utils
import { hideScrollbarX } from "../../../utils/cssStyles";
// components
import { NavSectionMini } from "../../../components/nav-section";
//
import NavToggleButton from "./NavToggleButton";

import getSidebarConfig from "./config-navigation";
import { useMemo } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useLocales } from "../../../locales";

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useAuthContext();
  const { currentLang } = useLocales();

  const navConfig = useMemo(
    () => getSidebarConfig(user.role, currentLang.value),
    [user, currentLang]
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
        mt: 20,
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: "fixed",
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        {/*<Logo sx={{ mx: "auto", my: 2 }} />*/}

        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  );
}
