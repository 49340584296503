import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// ----------------------------------------------------------------------

RHFTime.propTypes = {
  name: PropTypes.string,
};

export default function RHFTime({ name, label, id, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            {...field}
            fullWidth
            textField={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
                label: label,
                id: id,
              },
            }}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}
