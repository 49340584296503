const totalModules = ["tickets", "general", "rating", "address", "booking"];
export const useAvailableEventModules = (event) => {
  let modules = [];
  if (event) {
    totalModules.forEach((module) => {
      if (module === "tickets") {
        if (
          event?.tickets?.ticketTypes?.length > 0
          // event?.tickets?.entryType === "FREE"
        ) {
          modules.push(module);
        }
      } else {
        if (module === "booking") {
          if (event?.booking?.moduleStatus) {
            modules.push(module);
            if (event?.booking?.moduleStatusBookingMenu) {
              modules.push("bookingMenu");
            }
          }
        } else {
          return module in event ? modules.push(module) : null;
        }
      }
    });
  }
  return { modules };
};
