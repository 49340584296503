import { Box, Checkbox, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PATHS } from "../routes/paths";
import React from "react";
import { useLocales } from "../locales";

const CheckboxTermsAndConditions = ({
  checkboxValue,
  handleCheckbox,
  // If we are in an iframe, we need to open the links in a new tab
  isInIframe = false,
}) => {
  const { translate } = useLocales();
  return (
    <Box sx={{ my: 1, ml: 2 }}>
      <span
        style={{
          width: "max-content",
        }}
      >
        <Checkbox
          label={translate("registerPage.termsAndConditionsTitle")}
          checked={checkboxValue}
          onClick={handleCheckbox}
          sx={{ p: 0, mr: 1 }}
        />
        <label style={{ cursor: "pointer" }} onClick={handleCheckbox}>
          <Typography variant="subtitle2" component={"span"}>
            {translate("checkout.iReadAndAgree")}{" "}
            <Link
              component={RouterLink}
              variant="subtitle2"
              to={PATHS.auxiliary.termsAndConditions}
              target={isInIframe ? "_blank" : ""}
            >
              {translate("registerPage.termsAndConditions")}{" "}
            </Link>
            {translate("registerPage.message2")}{" "}
            <Link
              component={RouterLink}
              variant="subtitle2"
              to={PATHS.auxiliary.privacyPolicy}
              target={isInIframe ? "_blank" : ""}
            >
              {translate("registerPage.privacyPolicy")}
            </Link>
            .
          </Typography>
        </label>
      </span>
    </Box>
  );
};

export default CheckboxTermsAndConditions;
