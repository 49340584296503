import { gql } from "@apollo/client";

const GET_PAYMENT_METHODS = gql`
  query PaymentMethods {
    paymentMethods {
      id
      maskedPan
      expiration
    }
  }
`;

const GET_BILLING_INFO = gql`
  query BillingInfo {
    billingInfo {
      id
      firstName
      lastName
      address
      country
      countryId
      state
      stateId
      city
      cityId
      postCode
    }
  }
`;

export { GET_PAYMENT_METHODS, GET_BILLING_INFO };
