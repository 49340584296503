import Centrifuge from "centrifuge";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "src/hooks/useAuthContext";
import { apollo } from "./GlobalServicesProvider";
import {
  GET_ALL_RESERVATIONS,
  GET_BOOKINGS_FOR_CALENDAR_BY_ID_AND_DATE,
} from "src/graphql/queries/bookings";

const CentrifugoContext = createContext();

const useCentrifugo = () => {
  const context = useContext(CentrifugoContext);

  if (!context) {
    throw new Error("useCentrifugo must be used within a CentrifugoProvider");
  }

  return context;
};

const useWsChannel = (channelName, userId, callback) => {
  const { centrifuge } = useCentrifugo();
  const { centrifugoConnected } = useContext(CentrifugoContext);

  useEffect(() => {
    if (!centrifuge || !channelName || !userId) {
      return;
    }

    const subscription = centrifuge.subscribe(channelName, (message) => {
      callback(message);
    });

    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [centrifuge, channelName, userId, callback, centrifugoConnected]);
};

const CentrifugoProvider = ({ children }) => {
  const { user } = useAuthContext();
  const token = localStorage.getItem("accessToken");

  const [authToken, setAuthToken] = useState(null);
  const [centrifuge, setCentrifuge] = useState(null);
  const [centrifugoConnected, setCentrifugoConnected] = useState(false);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }
  }, [token, user]);

  useEffect(() => {
    const centrifugeInstance = new Centrifuge(
      "wss://ws.dev.nightz.app/connection/websocket"
    );

    if (authToken) {
      centrifugeInstance.setToken(authToken);
    }

    centrifugeInstance.on("connect", (context) => {
      setCentrifugoConnected(true);
    });

    centrifugeInstance.on("disconnect", (context) => {
      setCentrifugoConnected(false);
    });

    centrifugeInstance.connect();

    setCentrifuge(centrifugeInstance);

    return () => {
      if (centrifugeInstance) {
        centrifugeInstance.disconnect();
      }
    };
  }, [authToken]);

  const setWSAuthToken = (token) => {
    setAuthToken(token);
  };

  const CentrifugoContextValue = {
    authToken,
    setWSAuthToken,
    centrifuge,
    centrifugoConnected,
  };

  return (
    <CentrifugoContext.Provider value={CentrifugoContextValue}>
      {children}
    </CentrifugoContext.Provider>
  );
};

function InvalidateSubscriber({ userId }) {
  const { centrifugoConnected, centrifuge } = useCentrifugo();

  useEffect(() => {
    if (!centrifuge || !userId) return;

    const subscription = centrifuge.subscribe(`invalidate-${userId}`, (ev) => {
      if (ev.data.entity === "bookingModule") {
        apollo.refetchQueries({
          include: [
            GET_BOOKINGS_FOR_CALENDAR_BY_ID_AND_DATE,
            GET_ALL_RESERVATIONS,
          ],
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [centrifugoConnected]);

  return null;
}

function InvalidateProviderUserGuard() {
  const { user } = useAuthContext();

  if (!user || !user?.id) return null;

  return <InvalidateSubscriber userId={user?.id || ""} />;
}

const InvalidateProvider = () => {
  const localToken = localStorage.getItem("accessToken");

  if (!localToken) {
    return null;
  }

  return <InvalidateProviderUserGuard />;
};

export { useCentrifugo, useWsChannel, InvalidateProvider };
export default CentrifugoProvider;
