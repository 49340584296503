import { InstallMobile } from "@mui/icons-material";
import { Box, Button, Modal, Typography } from "@mui/material";
import Image from "src/components/image/Image";
import { useLocales } from "src/locales";

const DeleteForeignTicketsModal = ({
  modalOpen,
  setModalOpen,
  confirmDeleteCallback,
}) => {
  const { translate } = useLocales();

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={(theme) => ({
          background: (t) => t.palette.background.paper,
          borderRadius: 1.5,
          p: 2,
          width: theme.spacing(50),
          height: theme.spacing(50),

        })}
        boxShadow={4}
      >
        <Box
          sx={{
            maxWidth: "150px",
            maxHeight: "150px",
            margin: "auto",
          }}
        >
          <Image src="https://nightz-dev.s3.eu-central-1.amazonaws.com/assets/checkout_remove_tickets_modal.png" />
        </Box>

        <Typography textAlign={"center"} variant={"h4"}>
          {translate("productPage.itemsInYourCartTitle")}
        </Typography>

        <Typography
          textAlign={"center"}
          variant={"h6"}
          fontWeight={400}
          marginTop={1}
        >
          {translate("productPage.itemsInYourCartDescription")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 2,
            marginTop: 2,
          }}
        >
          <Button
            onClick={() => {
              confirmDeleteCallback();
            }}
            variant="contained"
            sx={{
              color: (t) => t.palette.grey[900],
              backgroundColor: (t) => t.palette.grey[200],
              "&:hover": {
                backgroundColor: (t) => t.palette.grey[300],
                boxShadow: "none",
              },
            }}
          >
            {translate("productPage.continue")}
          </Button>
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
            variant="contained"
            sx={{
              backgroundColor: (t) => t.palette.grey[900],
              color: (t) => t.palette.grey[200],
              textAlign: "center",
              "&:hover": {
                backgroundColor: (t) => t.palette.grey[700],
                boxShadow: "none",
              },
            }}
          >
            {translate("productPage.cancel")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteForeignTicketsModal;
