import { gql } from "@apollo/client";

const CLEAR_CART_ITEMS = gql`
    mutation ClearCartItems {
        clearCartItems
    }
`;

const DELETE_CART_ITEM = gql`
  mutation DeleteCartItem($cartItemId: ID!) {
    deleteCartItem(cartItemId: $cartItemId) {
      id
      items {
        id
        quantity
        ticketType {
          name
        }
      }
    }
  }
`;

const UPDATE_CART_ITEM = gql`
  mutation UpdateCartItem(
    $cartItemId: ID!
    $quantity: Int!
    $seatIds: [String]
    $seatsHoldToken: String
  ) {
    updateCartItem(
      cartItemId: $cartItemId
      quantity: $quantity
      seatIds: $seatIds
      seatsHoldToken: $seatsHoldToken
    ) {
      id
      items {
        id
        quantity
        seatsHoldToken
        seatIds
        ticketType {
          id
        }
      }
    }
  }
`;

const CREATE_CART_ITEM = gql`
  mutation CreateCartItem(
    $ticketId: ID!
    $quantity: Int!
    $seatIds: [String]
    $seatsHoldToken: String
  ) {
    createCartItem(
      ticketId: $ticketId
      quantity: $quantity
      seatIds: $seatIds
      seatsHoldToken: $seatsHoldToken
    ) {
      id
      items {
        id
        quantity
        ticketType {
          id
          name
          price
        }
        seatsIds
        seatsHoldToken
      }
    }
  }
`;

const CREATE_CART_ITEMS = gql`
  mutation CreateCartItems($cartItems: [CartItemCreate!]!) {
    createCartItems(cartItems: $cartItems) {
      id
      items {
        id
        ticketType {
          id
          price
          name
          event {
            general {
              id
              name
            }
          }
          eventId
          category {
            name
          }
        }
        quantity
        seatsHoldToken
        seatIds
      }
    }
  }
`;

const VALIDATE_CART = gql`
  mutation ValidateCart {
    validateCart {
      id
      items {
        id
        promoCode {
          id
          name
          percent
        }
        quantity
        ticketType {
          id
          ticketOrder
          isPublished
          order
          name
          description
          series
          total
          available
          price
          event {
            address {
              street
              city
              county
              venueName
              googleLocationLink
              geohash
              geopoints
            }
            general {
              id
              status
              visibility
              name
              description
              images
              endDate
              startDate
              fields
              organizerDisplayName
              organizerEmail
              organizerPhone
              organizerWebsite
              subCategory {
                name
              }
            }
            tickets {
              comission
              borderColor
            }
          }
          eventId
          categoryId
          category {
            id
            name
          }
          sellStartDate
          sellEndDate
        }
        seatsHoldToken
        seatIds
        bookingModule {
          event {
            address {
              street
              city
              county
              venueName
              googleLocationLink
              geohash
              geopoints
            }
            general {
              id
              status
              visibility
              name
              description
              images
              endDate
              startDate
              fields
              organizerDisplayName
              organizerEmail
              organizerPhone
              organizerWebsite
              subCategory {
                name
              }
            }
          }
          holdingFee
          id
        }
        metadata
      }
    }
  }
`;

export {
  CLEAR_CART_ITEMS,
  DELETE_CART_ITEM,
  UPDATE_CART_ITEM,
  CREATE_CART_ITEM,
  CREATE_CART_ITEMS,
  VALIDATE_CART,
};
