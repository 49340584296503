import { gql } from "@apollo/client";

const GET_USER_TICKETS = gql`
  query Tickets {
    tickets {
      documentLightUrl
      documentUrl
      id
      status
    }
  }
`;

const GET_USER_TICKETS_BY_EVENT_ID = gql`
  query Tickets($eventId: ID) {
    tickets(eventId: $eventId) {
      documentLightUrl
      documentUrl
      id
      status
      ticketType {
          promotionType
      }
    }
  }
`;
const GET_TICKET_TYPES = gql`
  query TicketCategories {
    ticketCategories {
      id
      name
    }
  }
`;

const GET_TICKETS_FOR_EVENT = gql`
  query Tickets(
    $eventId: ID
    $pagination: Pagination
    $status: TicketStats
    $orderIdOrSeriesSearchTerm: String
    $userSearchTerm: String
  ) {
    ticketsV2(
      eventId: $eventId
      pagination: $pagination
      status: $status
      orderIdOrSeriesSearchTerm: $orderIdOrSeriesSearchTerm
      userSearchTerm: $userSearchTerm
    ) {
      totalCount
      items {
        orderId
        boughtVia
        documentUrl
        documentLightUrl
        id
        invoiceUrl
        promo {
          id
          name
        }
        user {
          lastName
          firstName
          account {
            phone
            email
          }
        }
        status
        series
        ticketType {
          name
          order
          price
          total
          series
        }
        metadata
      }
    }
  }
`;

export {
  GET_USER_TICKETS,
  GET_USER_TICKETS_BY_EVENT_ID,
  GET_TICKET_TYPES,
  GET_TICKETS_FOR_EVENT,
};
