import { gql } from "@apollo/client";

const GET_SALES =
  gql(`query Query($aggregate: Aggregate, $filter: FilterSalesInput) {
    sales(aggregate: $aggregate, filter: $filter)
  }
  `);

const GET_REVENUE =
  gql(`query Query($aggregate: Aggregate, $filter: FilterSalesInput) {
  revenue(aggregate: $aggregate, filter: $filter)
}
  `);

export { GET_SALES, GET_REVENUE };
