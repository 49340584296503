import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { useLocales } from "../../../../locales";
import { useTheme } from "@mui/material/styles";
import { PrimaryButton } from "../listing-details/listingButtons";
import { useMutationWithAuth, useQueryWithAuth } from "src/graphql/instances";
import { CLEAR_CART_ITEMS, CREATE_CART_ITEMS } from "src/graphql/mutations";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_CART, GET_EVENT } from "../../../../graphql/queries";
import { useParams } from "react-router";
import EventImageLayout from "src/layouts/event";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTicketCard } from "../tickets/TicketShopCard";
import StickyFooter from "../../../../components/StickyFooter";
import { useAvailableEventModules } from "../../../../hooks/useAvailableEventModules";
import {
  CHECK_AVAILABILITY_BOOKING,
  CREATE_BOOKING_HOLDING_FEE_USER,
} from "../../../../graphql/mutations/bookings";
import ProductCard from "../shop/ProductCard";
import { Incrementer } from "../shop";
import BookingPage from "../booking/BookingPage";
import { fCurrency } from "../../../../utils/formatNumber";
import { useSnackbar } from "notistack";
import { PATHS } from "../../../../routes/paths";
import DeleteForeignTicketsModal from "../listing-details/DeleteForeignTicketsModal";

BookingWizard.propTypes = {};

export default function BookingWizard() {
  const { eventId } = useParams();

  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const { data: cartData } = useQueryWithAuth(GET_CART, {
    fetchPolicy: "no-cache",
  });
  const [clearCartItems] = useMutationWithAuth(CLEAR_CART_ITEMS);
  const [createCartItems] = useMutationWithAuth(CREATE_CART_ITEMS);
  const [createBookingHoldingFee] = useMutationWithAuth(
    CREATE_BOOKING_HOLDING_FEE_USER
  );
  const [getEvent] = useLazyQuery(GET_EVENT, {
    context: {
      headers: {
        "x-nightz-standard-behaviour": "true",
        "x-nightz-platform": "web",
        Authorization: token,
      },
    },
    variables: { eventId },
  });
  const [checkAvailabilityBooking] = useLazyQuery(CHECK_AVAILABILITY_BOOKING, {
    context: { headers: { Authorization: token } },
    fetchPolicy: "no-cache",
  });

  const [eventData, setEventData] = useState(null);
  const { modules } = useAvailableEventModules(eventData);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [showBooking, setShowBooking] = useState(null);
  const [ticketQuantities, updateTicketQuantities] = useState(null);
  const [selectedBookingConfig, setSelectedBookingConfig] = useState(null);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [hasOtherEventItemsInCart, setHasOtherEventItemsInCart] = useState(false);
  const [clearCartModalOpen, setClearCartModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const eventDataRes = await getEvent();
      setEventData(eventDataRes.data.event);
      if (eventDataRes.data.event?.tickets?.ticketTypes?.length > 0) {
        setActiveAccordion("tickets");
      } else {
        setActiveAccordion("booking");
      }

      const bookingAvailability = await checkAvailabilityBooking({
        variables: {
          showFirstAvailable: true,
          bookingModuleId: eventDataRes.data.event.booking.id,
          numberOfGuests: 1,
        },
      });
      setBookingData(
        bookingAvailability.data.checkAvailabilityBookingmodule.areas
      );
    })();
  }, []);

  useEffect(() => {
    const itemsToOtherEvent = cartData?.cart?.items.filter(it =>
      (it.ticketType && it.ticketType?.eventId !== eventId) ||
      (it.bookingModule && it.bookingModule.event.general.id !== eventId)
    );
    setHasOtherEventItemsInCart(!!itemsToOtherEvent?.length)

    const itemsToCurrentEvent = cartData?.cart?.items.filter(it =>
      (it.ticketType && it.ticketType?.eventId === eventId) ||
      (it.bookingModule && it.bookingModule.event.general.id === eventId)
    );
    const bookingCartItem = itemsToCurrentEvent?.find(it => !!it?.metadata?.booking);
    if (bookingCartItem) {
      setSelectedBookingConfig(bookingCartItem.metadata.booking);
    }
    const bookingMenuCartItems = itemsToCurrentEvent?.filter(it => !!it?.metadata?.bookingMenu);
    setSelectedMenus(bookingMenuCartItems?.map(it => ({ ...it.metadata.bookingMenu, quantity: it.quantity })));
  }, [cartData]);

  const doBuy = useCallback(async (skipCheckingOtherCartItems = false) => {
    try {
      if (!skipCheckingOtherCartItems && hasOtherEventItemsInCart) {
        setClearCartModalOpen(true);
        return;
      }

      await clearCartItems();

      if (ticketQuantities?.length) {
        await createCartItems({
          variables: {
            cartItems: ticketQuantities.map((it) => ({
              id: it.id,
              quantity: it.quantity,
            })),
          },
        });
      }

      if (selectedBookingConfig) {
        let booking = selectedBookingConfig;
        if (selectedMenus?.length) {
          booking["bookingMenus"] = selectedMenus.map((it) => ({
            id: it.id,
            quantity: it.quantity,
          }));
        }
        await createBookingHoldingFee({
          variables: { metadata: { booking } },
        });
      }
      navigate(PATHS.listings.checkout);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(translate("productPage.genericError"), {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  }, [ticketQuantities, selectedBookingConfig, selectedMenus]);

  if (!eventData) {
    return "Loading";
  }

  if (showBooking) {
    let bookingToEdit = { areaId: showBooking };
    if (selectedBookingConfig?.areaId === showBooking) {
      bookingToEdit = { ...selectedBookingConfig };
    }
    return (
      <BookingPage
        eventId={eventData.general.id}
        backHandler={() => setShowBooking(false)}
        removeHandler={() => {
          setShowBooking(false);
          setSelectedBookingConfig(null);
          setSelectedMenus([]);
        }}
        bookingToEdit={bookingToEdit}
        doneBookingHandler={(bookingConfig) => {
          setShowBooking(false);
          setSelectedBookingConfig(bookingConfig);
          if (
            modules.includes("bookingMenu") &&
            eventData.booking.menus.length
          ) {
            setActiveAccordion("bookingMenu");
          }
        }}
      />
    );
  }

  const ticketCount = ticketQuantities?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  const menuCount = selectedMenus?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  const menuCategories = Object.groupBy(
    eventData.booking.menus,
    (menu) => menu.category
  );
  const totalCost =
    (ticketQuantities?.reduce(
      (acc, item) => acc + item.quantity * item.price,
      0
    ) || 0) +
    (selectedBookingConfig?.holdingFee || 0) +
    (selectedMenus?.reduce(
      (acc, item) => acc + item.quantity * item.price,
      0
    ) || 0);

  const areaType = eventData?.booking?.areas;

  return (
    <EventImageLayout
      title={`Booking ${eventData?.general?.name}`}
      image={eventData?.general?.images[0]}
      fields={eventData?.general?.fields}
      useFullHeight={true}
    >
      <Box
        onClick={() => navigate(-1)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "88px",
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={40} sx={{ zIndex: 2 }} />
        <Typography
          variant="h5"
          color={theme.palette.text.primary}
          sx={{ zIndex: 2, mx: "auto" }}
        >
          {translate(`bookingWizard.title`)}
        </Typography>
      </Box>
      {modules.includes("tickets") && (
        <BookingWizardAccordion
          type={"tickets"}
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          summary={
            !!ticketCount &&
            `${ticketCount} ${
              ticketCount === 1
              ? translate("checkout.item")
              : translate("checkout.items")
            }`
          }
        >
          {getTicketCard(
            theme,
            translate,
            false,
            eventData,
            ticketQuantities,
            updateTicketQuantities
          )}
        </BookingWizardAccordion>
      )}
      {modules.includes("booking") && areaType && (
        <BookingWizardAccordion
          type={"booking"}
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          summary={!!selectedBookingConfig && `1 ${translate("checkout.item")}`}
        >
          {areaType.map((area) => (
            <ProductCard
              key={area.id}
              name={area.name}
              price={area.price}
              description={area.description}
              sx={{
                borderRadius: "8px",
              }}
            >
              <PrimaryButton
                variant="contained"
                onClick={() => setShowBooking(area.id)}
              >
                {selectedBookingConfig?.areaId === area.id
                  ? translate("checkout.changeQty")
                  : translate("bookings.newReservation")}
              </PrimaryButton>
            </ProductCard>
          ))}
        </BookingWizardAccordion>
      )}
      {modules.includes("bookingMenu") && eventData.booking.menus.length && (
        <BookingWizardAccordion
          type={"bookingMenu"}
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          summary={
            !!menuCount &&
            `${menuCount} ${
              menuCount === 1
              ? translate("checkout.item")
              : translate("checkout.items")
            }`
          }
        >
          {Object.keys(menuCategories).map((menuCategory) => (
            <Box
              key={menuCategory}
              sx={{
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6">
                {translate(`eventManager.menus.category.${menuCategory}`)}
              </Typography>
              {menuCategories[menuCategory].map((menu) => (
                <ProductCard
                  key={menu.id}
                  name={menu.name}
                  price={menu.price}
                  description={menu.description}
                >
                  <Incrementer
                    disabled={!selectedBookingConfig}
                    quantity={
                      selectedMenus?.find((it) => it.id === menu.id)
                        ?.quantity || 0
                    }
                    onDecrementQuantity={() => {
                      let menus = [...selectedMenus];
                      let existingValue = menus?.find(
                        (it) => it.id === menu.id
                      );
                      if (existingValue) {
                        existingValue.quantity = existingValue.quantity - 1;
                        if (!existingValue.quantity) {
                          menus = menus.filter((it) => it.id !== menu.id);
                        }
                      }
                      setSelectedMenus([...menus]);
                    }}
                    onIncrementQuantity={() => {
                      let menus = [...selectedMenus];
                      let existingValue = menus?.find(
                        (it) => it.id === menu.id
                      );
                      if (existingValue) {
                        existingValue.quantity = existingValue.quantity + 1;
                      } else {
                        menus.push({ ...menu, quantity: 1 });
                      }
                      setSelectedMenus([...menus]);
                    }}
                  />
                </ProductCard>
              ))}
            </Box>
          ))}
        </BookingWizardAccordion>
      )}

      <StickyFooter>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <PrimaryButton
            size="large"
            variant="contained"
            disabled={!ticketCount && !selectedBookingConfig && !menuCount}
            sx={{
              width: isMobile ? "calc(100% - 48px)" : "200px",
              margin: isMobile ? "16px 24px 24px 24px" : "auto",
            }}
            onClick={() => doBuy()}
          >
            {`${translate("productPage.buyNow")} - ${fCurrency(totalCost)} lei`}
          </PrimaryButton>
        </Box>
      </StickyFooter>

      <DeleteForeignTicketsModal
        modalOpen={clearCartModalOpen}
        setModalOpen={setClearCartModalOpen}
        confirmDeleteCallback={() => doBuy(true)}
      />
    </EventImageLayout>
  );
}

function BookingWizardAccordion({
  type,
  children,
  activeAccordion,
  setActiveAccordion,
  summary,
}) {
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <Accordion
      expanded={activeAccordion === type}
      onChange={() => {
        setActiveAccordion(activeAccordion !== type ? type : null);
      }}
      sx={accordionStyle}
    >
      <AccordionSummary sx={accordionSummaryStyle(type)}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "80px",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              fontWeight={theme.typography.fontWeightRegular}
            >
              {translate(`bookingWizard.${type}`)}
            </Typography>
            {summary && (
              <Typography
                variant="caption"
                fontWeight={theme.typography.fontWeightLight}
              >
                {summary}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              transform:
                activeAccordion === type ? "rotate(0deg)" : "rotate(180deg)",
              mr: 2,
              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              color: "inherit",
            }}
          >
            <ExpandMoreIcon />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

const accordionStyle = {
  "&.MuiAccordion-root::before": {
    height: 0,
  },
  "&.MuiAccordion-rounded": {
    my: 2,
    boxShadow: "none",
  },
  "&.Mui-expanded": {
    backgroundColor: "transparent",
  },
  // boxShadow: "0px 8px 16px 0px #0000000A",
};

const accordionSummaryStyle = (image) => ({
  "&.MuiAccordionSummary-root::before": {
    content: "''",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.1) 100%), url(/assets/booking/${image}.jpg)`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    borderRadius: "8px",
    zIndex: 1,
  },
  ".MuiAccordionSummary-content": {
    zIndex: 2,
  },

  height: "80px",
  marginTop: "16px",
  marginBottom: "16px",
});
