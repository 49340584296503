/**
 * This function is used to filter the input of a keyboard event to only allow numbers to be inputted.
 * To be used in a onKeyDown event handler, in a RHFTextField component, for example.
 * @param {Array} characterListToFilter - The list of characters to filter
 * @param {Object} event - The keyboard event object
 */
const numberKeyDownInputFilter = (characterListToFilter, event) => {
  characterListToFilter.includes(event.key) && event.preventDefault();
};

/**
 * This function removes leading zeros from a string and replaces it with "0" if the string is empty. If we have a situation where the number is 0.5, the leading zero will **NOT** be removed.
 * @method numberOnChangeInputFilter
 * @param {String} value - The value of the input field
 *
 */

const numberOnChangeInputFilter = (value) => {
  if (!value.includes(".") && value.length > 1) {
    value = value.replace(/^0+/, "") || "0";
  }

  if (value.includes(".")) {
    const [integer, decimal] = value.split(".");
    value = `${integer}.${decimal.slice(0, 2)}`;
  }
  return value;
};

const getNumberInputDefaultValue = (isEdit, initialValue) => {
  if (isEdit) {
    return initialValue === 0 ? "" : initialValue;
  } else {
    return initialValue ?? "";
  }
};

export {
  numberKeyDownInputFilter,
  numberOnChangeInputFilter,
  getNumberInputDefaultValue,
};
