import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Link,
} from "@mui/material";
import { useLocales } from "../../../../locales";
import Lightbox from "../../../../components/lightbox";
import Chip from "../../../../components/Chip";
import { useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  formatMinutesToHours,
  mapReservationStatusToColor,
  mapReservationStatusToTranslation,
} from "../../../../utils/reservationFunctions";
import Iconify from "../../../../components/iconify";
import {
  fDate,
  fTime,
  getDateWithoutTimezoneChange,
} from "../../../../utils/formatTime";
import { RESERVATION_CONSTANTS } from "../../../../config/constants/reservation-constants";

const IMG_HEIGHT = 300;

function BookingDetailsCard({ eventData, bookingData, cancelBooking }) {
  const [image, setImage] = useState(-1);
  const [openAlert, setOpenAlert] = useState(false);
  const { translate } = useLocales();
  const theme = useTheme();

  const imagesLightbox = eventData?.general?.images?.map((img) => ({
    src: img,
  }));
  const address = `${eventData?.address?.venueName}, ${eventData?.address?.street}, ${eventData?.address?.city}`;

  const handleOpenLightbox = () => {
    setImage(0);
  };

  const handleCloseLightbox = () => {
    setImage(-1);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const googleMapsLink = useMemo(() => {
    return eventData?.address?.googleLocationLink;
  }, [eventData?.address?.googleLocationLink]);

  const renderBookingData = (title, subtitle, icon) => {
    return (
      <Box
        sx={{
          mt: {
            xs: 0,
            md: 2,
          },
        }}
      >
        <Box display="flex" alignItems="center">
          {!!icon && <Iconify icon={icon} sx={{ marginRight: 1 }} />}
          <Typography variant="subtitle1">{title}</Typography>
        </Box>
        <Typography
          sx={{
            fontStyle: "italic",
            mt: {
              xs: 1,
              md: 0,
            },
          }}
          color={theme.palette.grey[500]}
        >
          {subtitle}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Card raised>
        <CardContent style={{ paddingBottom: 0, padding: 0 }}>
          <Grid container columns={{ xs: 12, md: 12 }}>
            <Grid
              item
              xs={12}
              md={4}
              onClick={handleOpenLightbox}
              sx={{
                cursor: "pointer",
                backgroundImage: `url(${eventData?.general?.images[0]})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                minHeight: IMG_HEIGHT,
              }}
            />
            <Grid item xs={12} md={8} sx={{ p: 2 }}>
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  justifyContent: {
                    xs: "left",
                    md: "space-between",
                  },
                  p: 1,
                }}
              >
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                    },
                    flexWrap: "nowrap",
                    width: {
                      xs: "100%",
                      md: "55%",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: (t) => t.typography.pxToRem(36),
                        fontWeight: 700,
                      }}
                    >
                      {eventData?.general?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {googleMapsLink ? (
                      <Link
                        href={googleMapsLink}
                        color={"inherit"}
                        target="_blank"
                      >
                        <Typography
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {address}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                      >
                        {address}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                    },
                    flexWrap: "nowrap",
                    width: {
                      xs: "100%",
                      md: "45%",
                    },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "left",
                        md: "flex-end",
                      },
                    }}
                  >
                    <Typography variant="h4" textAlign="right">
                      {translate("bookings.bookingDetails.title")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: {
                          xs: "left",
                          md: "flex-end",
                        },
                      }}
                      color={theme.palette.grey[500]}
                    >
                      {bookingData.id}
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "left",
                        md: "flex-end",
                      },
                      mb: 3,
                      mt: 1,
                      ml: 1,
                    }}
                  >
                    <Chip
                      label={translate(
                        mapReservationStatusToTranslation(bookingData.status)
                      )}
                      color={mapReservationStatusToColor(bookingData.status)}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  {renderBookingData(
                    translate("bookings.name"),
                    `${bookingData.organizer.firstName} ${bookingData.organizer.lastName}`
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {renderBookingData(
                    translate("bookings.guests"),
                    `${bookingData.numberOfGuests} ${translate(
                      bookingData.numberOfGuests > 1
                        ? "bookings.numberOfGuests"
                        : "bookings.numberOfGuest"
                    )}`,
                    "eva:people-fill"
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {renderBookingData(
                    translate("bookings.area"),
                    bookingData.tables
                      .map((table) => table?.area?.name)
                      .join(", "),
                    "teenyicons:pin-solid"
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {renderBookingData(
                    translate("bookings.date"),
                    fDate(bookingData.bookedDate),
                    "mdi:calendar"
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {renderBookingData(
                    translate("bookings.time"),
                    fTime(getDateWithoutTimezoneChange(bookingData.bookedHour)),
                    "mdi:clock"
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {renderBookingData(
                    translate("bookings.length"),
                    formatMinutesToHours(bookingData.duration),
                    "ph:tag-chevron-fill"
                  )}
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                {![
                  RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_USER,
                  RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_USER,
                  RESERVATION_CONSTANTS.RESERVATION_STATUS.DENIED,
                ].includes(bookingData.status) && (
                  <Button
                    color="error"
                    variant="outlined"
                    size="large"
                    onClick={handleOpenAlert}
                  >
                    {translate("bookings.cancelReservation")}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Lightbox
        open={image >= 0}
        slides={imagesLightbox}
        close={handleCloseLightbox}
      />
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-describedby="close-reservation-alert"
      >
        <DialogTitle> {translate("bookings.alertTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="close-reservation-alert">
            {translate("bookings.alertText")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              cancelBooking();
              handleCloseAlert();
            }}
          >
            {translate("bookings.yes")}
          </Button>
          <Button onClick={handleCloseAlert}>{translate("bookings.no")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BookingDetailsCard;
