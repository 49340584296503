import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
// @mui

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  inputProps: PropTypes.object,
};

export default function RHFAutocomplete({
  name,
  label,
  helperText,
  inputProps,
  options,
  multiple,
  ...other
}) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          options={options}
          onChange={(event, newValue, reason) => {
            if (
              newValue &&
              newValue?.includes("All") &&
              reason === "selectOption"
            ) {
              setValue(name, options, { shouldValidate: true });
            } else {
              if (reason === "clear") {
                if (multiple === true) {
                  setValue(name, [], { shouldValidate: true });
                } else {
                  setValue(name, "", { shouldValidate: true });
                }
              } else {
                if (multiple === true) {
                  setValue(
                    name,
                    newValue.filter((t) => t !== "All"),
                    { shouldValidate: true }
                  );
                } else {
                  setValue(name, newValue, { shouldValidate: true });
                }
              }
            }
          }}
          renderInput={(props) => {
            return (
              <TextField
                label={label}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...props}
                inputProps={{
                  ...props.inputProps,
                  autoComplete: "new-password",
                }}
              />
            );
          }}
          multiple={multiple}
          {...other}
        />
      )}
    />
  );
}
