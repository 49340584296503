import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useLocales } from "../../../../locales";
import { RESERVATION_CONSTANTS } from "../../../../config/constants/reservation-constants";

export const BookingAccordionNoOptions = ({ loadingCheckAvailability }) => {
  const { translate } = useLocales();

  const [displayedMessage, setDisplayedMessage] = useState(
    loadingCheckAvailability
  );

  useEffect(() => {
    let timer;
    if (loadingCheckAvailability) {
      // Immediately set the loading message when loading starts
      setDisplayedMessage(translate("bookings.loadingBookingCardOptions"));
    } else {
      // Introduce a delay before showing the "no options" message
      timer = setTimeout(() => {
        setDisplayedMessage(
          translate("bookings.noAvailableBookingCardOptions")
        );
      }, RESERVATION_CONSTANTS.TIMEOUT_BETWEEN_CHANGE_TEXT);
    }

    // Cleanup the timer on component unmount or when loadingCheckAvailability changes again
    return () => clearTimeout(timer);
  }, [loadingCheckAvailability]);

  return (
    <Typography
      variant="subtitle1"
      sx={{
        textAlign: "center",
      }}
    >
      {displayedMessage}
    </Typography>
  );
};

BookingAccordionNoOptions.propTypes = {
  loadingCheckAvailability: PropTypes.bool,
};
