import { gql } from "@apollo/client";

const PLACE_ORDER = gql`
  mutation PlaceOrder(
    $paymentMethodId: ID
    $phone: String
    $email: String
    $pageView: PageView
  ) {
    placeOrder(
      paymentMethodId: $paymentMethodId
      phone: $phone
      email: $email
      pageView: $pageView
    )
  }
`;

const COMPLETE_ORDER = gql`
  mutation CompleteOrder($btOrderId: String!) {
    completeOrder(btOrderId: $btOrderId) {
      status
      activities
      paymentProviderStatusCode
    }
  }
`;

export { PLACE_ORDER, COMPLETE_ORDER };
