import PropTypes from "prop-types";
// @mui
import { Box, Card, Typography } from "@mui/material";
// components
import Carousel from "../../../../components/carousel";
import useResponsive from "../../../../hooks/useResponsive";
import { SkeletonProductItem } from "../../../../components/skeleton";

// ----------------------------------------------------------------------

LoadingCardCarousel.propTypes = {
  sx: PropTypes.object,
};

export default function LoadingCardCarousel({ sx = {} }) {
  const isMobile = useResponsive("down", "md");

  return (
    <>
      <Card></Card>
      <Box sx={{ mb: 4, ...sx }}>
        <Typography variant="h6" sx={{ ml: 1, mb: 1 }}>
          Loading...
        </Typography>
        <Carousel
          arrows={false}
          autoplay={false}
          slidesToShow={isMobile ? 3 : 6}
          swipeToSlide={false}
        >
          {[...Array(6)].map((_, index) => (
            <SkeletonProductItem key={index} sx={{ mx: 1 }} />
          ))}
        </Carousel>
      </Box>
      <Box sx={{ mb: 4, ...sx }}>
        <Carousel
          arrows={false}
          autoplay={false}
          slidesToShow={isMobile ? 3 : 6}
          swipeToSlide={false}
        >
          {[...Array(6)].map((_, index) => (
            <SkeletonProductItem key={index} sx={{ mx: 1 }} />
          ))}
        </Carousel>
      </Box>
      <Box sx={{ mb: 4, ...sx }}>
        <Carousel
          arrows={false}
          autoplay={false}
          slidesToShow={isMobile ? 3 : 6}
          swipeToSlide={false}
        >
          {[...Array(6)].map((_, index) => (
            <SkeletonProductItem key={index} sx={{ mx: 1 }} />
          ))}
        </Carousel>
      </Box>
    </>
  );
}
