import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Switch, FormControlLabel, FormHelperText } from "@mui/material";

// ----------------------------------------------------------------------

RHFSwitch.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  onCustomChange: PropTypes.func,
};

export default function RHFSwitch({
  name,
  helperText,
  onCustomChange = null,
  ...other
}) {
  const { control } = useFormContext();

  const handleChange = (event, field) => {
    if (onCustomChange) {
      onCustomChange(event);
    } else {
      field.onChange(event);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={(ev) => handleChange(ev, field)} // Use the custom handleChange function
              />
            }
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
