class Account {
  constructor(data) {
    this.email = data.email;
    this.id = data.id;
    this.phone = data.phone;
    this.accountType = data.accountType;
    this.role = getRole(data.profile.__typename);
  }
}

export class User extends Account {
  constructor(data) {
    super(data);
    this.avatar = data.profile.avatar;
    this.firstName = data.profile.firstName;
    this.lastName = data.profile.lastName;
    this.username = data.profile.username;
  }
}

export class Company extends Account {
  constructor(data) {
    super(data);
    this.avatar = data.profile.avatar;
    this.firstName = data.profile.firstName;
    this.lastName = data.profile.lastName;
    this.approval = data.profile.approval;
    this.comission = data.profile.comission;
    this.companyInfo = data.profile.companyInfo;
    this.companyId = data.profile.id;
  }
}

export class Admin extends Account {
  constructor(data) {
    super(data);
    this.name = data.profile.name;
  }
}

export class Shadow {
  constructor(data) {
    this.id = data.id;
    this.accountType = data?.accountType ?? "";
    this.avatar = data?.profile?.avatar ?? "";
    this.firstName = data?.profile?.firstName ?? "";
    this.isDeleted = data?.profile?.isDeleted ?? "";
    this.lastName = data?.profile?.lastName ?? "";
    this.username = data?.profile?.username ?? "";
    this.role = getRole(data.__typename) ?? "";
    this.email = data?.profile?.account?.email ?? "";
    this.phone = data?.profile?.account?.phone ?? "";
  }
}

export const accountFactory = (data) => {
  if (data.accountType === "PERSISTED") {
    const typename = data.profile.__typename;
    if (typename === "UserProfile") {
      return new User(data);
    }
    if (typename === "CompanyProfile") {
      return new Company(data);
    }
    if (typename === "AdminProfile") {
      return new Admin(data);
    }
  }
  if (data.accountType === "SHADOW") {
    const typename = data.__typename;
    if (typename === "ShadowAccount") {
      return new Shadow(data);
    }
  }
};

const getRole = (typename) => {
  if (typename === "UserProfile") {
    return "user";
  }
  if (typename === "CompanyProfile") {
    return "company";
  }
  if (typename === "AdminProfile") {
    return "admin";
  }
  if (typename === "ShadowAccount") {
    return "shadow";
  }
};
