import { gql } from "@apollo/client";

const CREATE_TICKET_TYPE = gql`
  mutation CreateTicketType(
    $eventId: ID!
    $name: String!
    $ticketCategoryId: ID!
    $sellStartDate: DateTime!
    $sellEndDate: DateTime!
    $series: String!
    $total: Int!
    $price: Float!
    $order: Int
    $isPublished: Boolean
    $description: String
  ) {
    createTicketType(
      eventId: $eventId
      name: $name
      ticketCategoryId: $ticketCategoryId
      sellStartDate: $sellStartDate
      sellEndDate: $sellEndDate
      series: $series
      total: $total
      price: $price
      order: $order
      isPublished: $isPublished
      description: $description
    ) {
      total
      ticketOrder
      series
      sellStartDate
      sellEndDate
      price
      order
      name
      isPublished
      id
      eventId
      description
      categoryId
      category {
        id
        name
      }
      available
      promotionType
      promotionMetadata
    }
  }
`;
const DELETE_TICKET_TYPE = gql`
  mutation DeleteTicketType($ticketTypeId: ID!) {
    deleteTicketType(ticketTypeId: $ticketTypeId) {
      id
    }
  }
`;

const UPDATE_TICKET_TYPE = gql`
  mutation UpdateTicketType(
    $ticketTypeId: ID!
    $name: String
    $sellStartDate: DateTime
    $sellEndDate: DateTime
    $isPublished: Boolean
    $order: Int
    $description: String
    $series: String
    $total: Int
    $price: Float
    $promotionType: String
    $promotionMetadata: Object
  ) {
    updateTicketType(
      ticketTypeId: $ticketTypeId
      name: $name
      sellStartDate: $sellStartDate
      sellEndDate: $sellEndDate
      isPublished: $isPublished
      order: $order
      description: $description
      series: $series
      total: $total
      price: $price
      promotionType: $promotionType
      promotionMetadata: $promotionMetadata
    ) {
      id
    }
  }
`;

const CREATE_SPECIAL_TICKET = gql`
  mutation CreateTicketSpecial($items: [TicketSpecialItems!]!) {
    createTicketSpecial(items: $items)
  }
`;

export {
  CREATE_TICKET_TYPE,
  DELETE_TICKET_TYPE,
  UPDATE_TICKET_TYPE,
  CREATE_SPECIAL_TICKET,
};
