import { gql } from "@apollo/client";

const CREATE_PROMO_CODE = gql(`mutation CreatePromoCode($eventId: ID!, 
$name: String!, $percent: Float!) {
  createPromoCode(eventId: $eventId, name: $name, percent: $percent) {
    id
    name
    percent
  }
}`);

const UPDATE_PROMO_CODE = gql(`mutation UpdatePromoCode($promoCodeId: ID!, 
$name: String, $percent: Float) {
  updatePromoCode(promoCodeId: $promoCodeId, name: $name, percent: $percent) {
    id
    name
    percent
  }
}`);

const DELETE_PROMO_CODE = gql(`mutation UpdatePromoCode($promoCodeId: ID!) {
  deletePromoCode(promoCodeId: $promoCodeId) {
    id
  }
}`);

const APPLY_PROMO_CODE = gql`
  mutation ApplyPromoCode($code: String!) {
    applyPromoCode(code: $code) {
      items {
        id
        promoCode {
          id
          name
          percent
        }
      }
    }
  }
`;

export {
  UPDATE_PROMO_CODE,
  CREATE_PROMO_CODE,
  DELETE_PROMO_CODE,
  APPLY_PROMO_CODE,
};
