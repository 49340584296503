import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// config
import { NAV } from "../../config-global";
// auth
import { useAuthContext } from "../../hooks/useAuthContext";
//
import Logo from "../logo";
import ProgressBar from "../progress-bar";
import { useSettingsContext } from "../settings";
import palette from "src/theme/palette";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ themeMode }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: palette(themeMode).background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  /**
   * useLocation dont work outside of router component so we use window.location.pathname instead
   */
  // const { pathname } = useLocation();
  const pathname = window.location.pathname;

  const isDesktop = useResponsive("up", "lg");
  const { isInitialized } = useAuthContext();
  const { themeLayout, themeMode } = useSettingsContext();
  const isDashboard =
    isInitialized && pathname.includes("/dashboard") && isDesktop;

  const size =
    (themeLayout === "mini" && NAV.W_DASHBOARD_MINI) ||
    (themeLayout === "vertical" && NAV.W_DASHBOARD) ||
    128;

  return (
    <>
      <ProgressBar />

      <StyledRoot
        themeMode={themeMode}
        sx={{
          ...(isDashboard && {
            width: `calc(100% - ${size}px)`,
            ...(themeLayout === "horizontal" && {
              width: 1,
              height: `calc(100% - ${size}px)`,
            }),
          }),
        }}
      >
        {isDashboard ? (
          <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
        ) : (
          <>
            <m.div
              animate={{
                scale: [
                  1, 1, 0.999, 0.998, 0.997, 0.995, 0.992, 0.99, 0.987, 0.99,
                  0.992, 0.995, 0.997, 0.998, 0.999, 1, 1,
                ],
                opacity: [
                  1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.35, 0.4, 0.5, 0.6, 0.7,
                  0.8, 0.9, 1,
                ],
              }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeatDelay: 0,
                repeat: Infinity,
              }}
            >
              <Logo disabledLink sx={{ width: 300, height: 300 }} />
            </m.div>
            {/*<Box*/}
            {/*  component={m.div}*/}
            {/*  animate={{*/}
            {/*    scale: [1.6, 1, 1, 1.6, 1.6],*/}
            {/*    rotate: [270, 0, 0, 270, 270],*/}
            {/*    opacity: [0.25, 1, 1, 1, 0.25],*/}
            {/*    borderRadius: ["25%", "25%", "50%", "50%", "25%"],*/}
            {/*  }}*/}
            {/*  transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}*/}
            {/*  sx={{*/}
            {/*    width: 100,*/}
            {/*    height: 100,*/}
            {/*    position: "absolute",*/}
            {/*    border: (theme) =>*/}
            {/*      `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,*/}
            {/*  }}*/}
            {/*/>*/}

            {/*<Box*/}
            {/*  component={m.div}*/}
            {/*  animate={{*/}
            {/*    scale: [1, 1.2, 1.2, 1, 1],*/}
            {/*    rotate: [0, 270, 270, 0, 0],*/}
            {/*    opacity: [1, 0.25, 0.25, 0.25, 1],*/}
            {/*    borderRadius: ["25%", "25%", "50%", "50%", "25%"],*/}
            {/*  }}*/}
            {/*  transition={{*/}
            {/*    ease: "linear",*/}
            {/*    duration: 3.2,*/}
            {/*    repeat: Infinity,*/}
            {/*  }}*/}
            {/*  sx={{*/}
            {/*    width: 120,*/}
            {/*    height: 120,*/}
            {/*    position: "absolute",*/}
            {/*    border: (theme) =>*/}
            {/*      `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,*/}
            {/*  }}*/}
            {/*/>*/}
          </>
        )}
      </StyledRoot>
    </>
  );
}
