import { memo } from "react";

function LengthIcon({}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3.5 18.99L14.5 19C15.17 19 15.77 18.67 16.13 18.16L20.5 12L16.13 5.84C15.77 5.33 15.17 5 14.5 5L3.5 5.01L8.34 12L3.5 18.99Z" fill="white"/>
    </svg>
  );
}

export default memo(LengthIcon);
