import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { IncrementorButton } from "../listing-details/listingButtons";
import Iconify from "../../../../components/iconify";

Incrementer.propTypes = {
  available: PropTypes.number,
  quantity: PropTypes.number,
  onIncrementQuantity: PropTypes.func,
  onDecrementQuantity: PropTypes.func,
  totalItems: PropTypes.number,
  totalQty: PropTypes.number,
  initialQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
};

export default function Incrementer({
                                      disabled,
                                      available,
                                      quantity,
                                      onIncrementQuantity,
                                      onDecrementQuantity,
                                      totalItems,
                                      totalQty,
                                      initialQuantity,
                                      maxQuantity,
                                    }) {
  return (
    <Box
      sx={{
        py: 0.5,
        px: 0.75,
        lineHeight: 0,
        display: "flex",
        alignItems: "center",
      }}
    >
      <IncrementorButton
        size="small"
        color="inherit"
        disabled={disabled || quantity <= 0}
        onClick={onDecrementQuantity}
      >
        <Iconify icon={"eva:minus-fill"} width={14} height={14} />
      </IncrementorButton>

      <Typography
        variant="body2"
        component="span"
        sx={{ width: 40, textAlign: "center" }}
      >
        {quantity}
      </Typography>

      <IncrementorButton
        size="small"
        color="inherit"
        disabled={
          disabled ||
          (available && quantity >= available) ||
          (maxQuantity && totalItems - initialQuantity + totalQty >= maxQuantity)
        }
        onClick={onIncrementQuantity}
      >
        <Iconify icon={"eva:plus-fill"} width={14} height={14} />
      </IncrementorButton>
    </Box>
  );
}