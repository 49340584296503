import { Stack, Typography } from "@mui/material";
import Iconify from "../../../../components/iconify";
import PropTypes from "prop-types";

const RatingShowcaseSummaryDetails = ({ rating, address }) => {
  return (
    <>
      <Stack direction="row" alignItems={"center"}>
        <Typography align="center" lineHeight={0.8} fontSize={24}>
          4.2
        </Typography>
        <Iconify icon="material-symbols:star" width={24} />
      </Stack>
      <Stack
        direction="column"
        sx={{ borderLeft: "2px solid white", paddingLeft: 1 }}
      >
        <Typography
          variant="h6"
          align="left"
          lineHeight={0.8}
          onClick={() => {
            window.open(address.googleLocationLink);
          }}
        >
          {address.street && address.street.toUpperCase()}
        </Typography>
        {rating.program && (
          <Typography marginTop={1} align="left" fontSize={20} lineHeight={1}>
            {rating.program.toUpperCase()}
          </Typography>
        )}
      </Stack>
    </>
  );
};

RatingShowcaseSummaryDetails.propTypes = {
  rating: PropTypes.object,
  address: PropTypes.object,
};

export default RatingShowcaseSummaryDetails;
