import { gql } from "@apollo/client";

const GET_CART_QUANTITY = gql`
  query Cart {
    cart {
      id
      items {
        id
        quantity
      }
    }
  }
`;

const GET_CART = gql`
  query Cart {
    cart {
      items {
        id
        promoCode {
          id
          name
          percent
        }
        quantity
        ticketType {
          id
          ticketOrder
          isPublished
          order
          name
          description
          series
          total
          available
          price
          event {
            address {
              street
              city
              county
              venueName
              googleLocationLink
              geohash
              geopoints
            }
            general {
              id
              status
              visibility
              name
              description
              images
              endDate
              startDate
              fields
              organizerDisplayName
              organizerEmail
              organizerPhone
              organizerWebsite
              subCategory {
                name
              }
            }
            tickets {
              comission
              borderColor
            }
          }
          eventId
          categoryId
          category {
            id
            name
          }
          sellStartDate
          sellEndDate
        }
        seatsHoldToken
        seatIds
        bookingModule {
          event {
            address {
              street
              city
              county
              venueName
              googleLocationLink
              geohash
              geopoints
            }
            general {
              id
              status
              visibility
              name
              description
              images
              endDate
              startDate
              fields
              organizerDisplayName
              organizerEmail
              organizerPhone
              organizerWebsite
              subCategory {
                name
              }
            }
          }
          holdingFee
          id
        }
        metadata
      }
      id
    }
  }
`;

export { GET_CART_QUANTITY, GET_CART };
