import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useSettingsContext } from "./settings";

SunSwitch.propTypes = {
  checked: PropTypes.bool,
  sx: PropTypes.object,
  size: PropTypes.number,
  onChange: PropTypes.func,
};

export default function SunSwitch({ checked, sx, onChange, size = 32 }) {
  const { themeMode } = useSettingsContext();
  const isLightTheme = themeMode === "light";

  return (
    <Box
      sx={{
        height: `${size}px`,
        width: `${2 * size}px`,
        backgroundColor: isLightTheme ? "#ffffff" : "#2e3237",
        border: isLightTheme ? "2px solid #2e3237" : "",
        display: "flex",
        alignItems: "center",
        borderRadius: 100,
        cursor: "pointer",
        ...sx,
      }}
      onClick={onChange}
    >
      <DarkModeSwitch
        style={{
          marginLeft: 5,
          marginRight: 5,
          position: "relative",
          left: checked ? 0 : size - 5,
          transition: "all 0.5s",
        }}
        onChange={() => {}}
        checked={checked}
        size={size - 10}
        moonColor="#FFC72A"
        sunColor="#FFC72A"
      />
    </Box>
  );
}
