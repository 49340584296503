import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
// @mui
import { Box } from "@mui/material";
import { metaHelmetTagsGeneral } from "../config/meta-helmet-tags-general";

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => {
  return (
    <>
      <Helmet prioritizeSeoTags={true}>
        <title>{`${title} | Nightz`}</title>
        {metaHelmetTagsGeneral}
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
