const { gql } = require("@apollo/client");

export const GET_ADMIN_INVOICES = gql`
  query InvoicingPackages(
    $pagination: Pagination
    $companyNameFilter: String
    $createdFrom: DateTime
    $createdTo: DateTime
    $documentUid: String
    $status: [InvoicingPackageStatus]
    $companyId: [String]
    $eventId: String
    $eventNameFilter: String
  ) {
    invoicingPackages(
      pagination: $pagination
      companyNameFilter: $companyNameFilter
      createdFrom: $createdFrom
      createdTo: $createdTo
      documentUid: $documentUid
      statuses: $status
      companyIds: $companyId
      eventId: $eventId
      eventNameFilter: $eventNameFilter
    ) {
      totalCount
      items {
        company {
          id
          firstName
          lastName
          avatar
          comission
          approval
          companyInfo {
            id
            companyName
            iban
          }
        }
        id
        createdAt
        status
        documents {
          type
          createdAt
          name
          id
          totalAmount
        }
      }
    }
  }
`;

export const GET_INVOICE_PACKAGE = gql`
  query InvoicingPackage($invoicingPackageId: ID!) {
    invoicingPackage(id: $invoicingPackageId) {
      id
      documents {
        createdAt
        fromCompanyName
        toCompanyName
        totalAmount
        type
        items {
          amount
          description
          quantity
          id
          taxesAmount
          title
          totalAmount
        }
        fromLegalRepresentativeName
        toLegalRepresentativeName
        fromStreet
        toStreet
        id
        taxesAmount
        fromEmail
        fromPhone
        toPhone
        toEmail
        uid
        toCounty
        toCountry
        fromCounty
        fromCountry
        toTradeRegistryNumber
        toUniqueRegistrationCode
        fromUniqueRegistrationCode
        fromTradeRegistryNumber
        fromIban
        toIban
        fromBankName
        toBankName
        fromCity
        toCity
        toCompanyName
        toVatObligations
      }
      status
      dueDate
    }
  }
`;

export const GET_INVOICES_PACKAGE_DRAFTS = gql`
 mutation InvoicingPackageDrafts(
    $eventId: String
    $createdTo: DateTime
    $createdFrom: DateTime
    $companyId: String
  ) {
    invoicingPackageDrafts(
      eventId: $eventId
      createdTo: $createdTo
      createdFrom: $createdFrom
      companyId: $companyId
    ) {
      orderIds
      invoicingPackages {
        id
        status
        createdAt
        documents {
          createdAt
          fromCompanyName
          toCompanyName
          totalAmount
          type
          items {
            amount
            description
            quantity
            id
            taxesAmount
            title
            totalAmount
          }
          fromLegalRepresentativeName
          toLegalRepresentativeName
          fromStreet
          toStreet
          id
          taxesAmount
          fromEmail
          fromPhone
          toPhone
          toEmail
          uid
          toCounty
          toCountry
          fromCounty
          fromCountry
          fromCity
          fromBankName
          fromIban
          fromTradeRegistryNumber
          fromUniqueRegistrationCode
          fromVatObligations
          toTradeRegistryNumber
          toUniqueRegistrationCode
          toVatObligations
          toIban
          toCity
          toBankName
        }
        company {
          companyInfo {
            companyName
          }
        }
        dueDate
      }
    }
  }
`;

export const CREATE_INVOICES = gql`
  mutation InvoicingGeneration($orderIds: [String!]) {
    invoicingGeneration(orderIds: $orderIds)
  }
`;

export const GENERATE_INVOICING_PACKAGE_FOR_EVENT = gql`
  mutation InvoicingGeneration(
    $eventId: String
    $createdFrom: DateTime
    $createdTo: DateTime
  ) {
    invoicingGeneration(
      eventId: $eventId
      createdFrom: $createdFrom
      createdTo: $createdTo
    )
  }
`;

export const GENERATE_MANUAL_INVOICE = gql`
  mutation InvoicingPackageManualCreation(
    $invoicingPackageDto: InvoicingPackageDto!
  ) {
    invoicingPackageManualCreation(invoicingPackageDto: $invoicingPackageDto)
  }
`;

export const UPDATE_INVOICE_PACKAGE_STATUS = gql`
  mutation InvoicingPackagesUpdateStatus(
    $ids: [ID!]!
    $status: InvoicingPackageStatus!
  ) {
    invoicingPackagesUpdateStatus(ids: $ids, status: $status)
  }
`;

export const DOWNLOAD_INVOICE_PDF = gql`
  query Query($invoicingDocumentPdfUrlId: ID!) {
    invoicingDocumentPdfUrl(id: $invoicingDocumentPdfUrlId)
  }
`;

export const DOWNLOAD_INVOICE_XML = gql`
  query Query($invoicingDocumentXmlUrlId: ID!) {
    invoicingDocumentXmlUrl(id: $invoicingDocumentXmlUrlId)
  }
`;

export const DOWNLOAD_INVOICE_PDFS_ZIP = gql`
  query Query($ids: [ID!]!) {
    invoicingPackagesDocumentPdfZip(ids: $ids) {
      zipBase64
    }
  }
`;

export const DOWNLOAD_INVOICE_XMLS_ZIP = gql`
  query Query($ids: [ID!]!) {
    invoicingPackagesDocumentXmlZip(ids: $ids) {
      zipBase64
    }
  }
`;

export const DELETE_INVOICES_PACKAGE = gql`
  mutation InvoicingPackagesDelete($ids: [String!]!) {
    invoicingPackagesDelete(ids: $ids)
  }
`;
