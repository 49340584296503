import { Box, Card, Typography } from "@mui/material";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import EmailIcon from "../../assets/icons/EmailIcon";
import CircularLogo from "../logo/CircularLogo";
import { StyledSectionBg } from "../../layouts/login/styles";
import { useAuthContext } from "../../hooks/useAuthContext";
import CONTACT_INFO from "src/config/constants/contact-information";

const RepContactDetails = () => {
  const { user } = useAuthContext();
  if (user.role === "company") {
    return (
      <Card sx={{ mx: "16px", my: 2 }}>
        <StyledSectionBg />
        <Box
          sx={{
            display: "flex",
            width: "248px",
            padding: "8px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <CircularLogo
              sx={{
                maxHeight: "30px",
                maxWidth: "30px",
              }}
            />
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              Customer Support
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              pl: "8px",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <PhoneIcon />
            <Typography sx={{ fontSize: 14 }}>{CONTACT_INFO.PHONE}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              pl: "8px",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <EmailIcon />
            <Typography sx={{ fontSize: 14 }}>{CONTACT_INFO.EMAIL}</Typography>
          </Box>
        </Box>
      </Card>
    );
  } else {
    return null;
  }
};

export default RepContactDetails;
