import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  InputAdornment,
  InputBase,
  Link,
  Popper,
  Slide,
  Typography,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
import SearchNotFound from "../../../components/search-not-found";
//
import { SEARCH_LISTINGS } from "../../../graphql/queries/listings";
import { useLazyQuery } from "@apollo/client";
import { PATHS } from "../../../routes/paths";
import { paramCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
import Image from "../../../components/image";
import useResponsive from "../../../hooks/useResponsive";
import ReactGA from "react-ga4";
import { useLocales } from "src/locales";
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const StyledSearchbar = styled("div")(({ theme, isMd }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: isMd ? "absolute" : "fixed",
  alignItems: "center",
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const StyledPopper = styled((props) => <Popper {...props} />)(
  ({ theme, is }) => ({
    left: `8px !important`,
    top: `${APPBAR_MOBILE + 8}px !important`,
    width: "calc(100% - 16px) !important",
    transform: "none !important",
    [theme.breakpoints.up("md")]: {
      top: `${APPBAR_DESKTOP + 8}px !important`,
    },
    "& .MuiAutocomplete-paper": {
      padding: theme.spacing(1, 0),
    },
    "& .MuiListSubheader-root": {
      "&.MuiAutocomplete-groupLabel": {
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        ...theme.typography.overline,
        top: 0,
        margin: 0,
        lineHeight: "48px",
        borderRadius: theme.shape.borderRadius,
      },
    },
    "& .MuiAutocomplete-listbox": {
      "& .MuiAutocomplete-option": {
        padding: theme.spacing(0.5, 2),
        margin: 0,
        display: "block",
        border: `dashed 1px transparent`,
        borderBottomColor: theme.palette.divider,
        "&:last-of-type": {
          borderBottomColor: "transparent",
        },
        "&:hover": {
          borderColor: theme.palette.primary.main,
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.hoverOpacity
          ),
        },
      },
    },
  })
);

// ----------------------------------------------------------------------

function Searchbar({ externalOpen }) {
  const token = localStorage.getItem("accessToken");
  const [searchListings] = useLazyQuery(SEARCH_LISTINGS, {
    context: {
      headers: {
        "x-nightz-standard-behaviour": "true",
        "x-nightz-platform": "web",
        Authorization: token,
      },
    },
  });

  const navigate = useNavigate();
  const isMd = useResponsive("up", "md");
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { translate } = useLocales();

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (externalOpen) {
      handleOpen();
    }
  }, [externalOpen]);

  useEffect(() => {
    if (!externalOpen) {
      handleClose();
    }
  }, [externalOpen]);

  const onSearch = async (value) => {
    if (!value) {
      setItems([]);
      setLoading(false);
      return;
    }
    try {
      ReactGA.event("search", {
        search_term: value,
      });
    } catch (e) { }

    const result = await searchListings({
      variables: { term: value },
    });

    if (!result?.data || !result?.data?.newSearch) {
      setLoading(false);
      return;
    }

    const newItems = result?.data?.newSearch?.event
      .map((srcRes) => {
        if (srcRes) {
          return {
            name: srcRes?.name,
            image: srcRes?.images[0],
            id: srcRes?.id,
            subcategory: srcRes?.subCategory?.name,
          };
        }
        return {};
      })
      .filter((i) => Boolean(i.name))
      .sort((a, b) => a.subcategory.localeCompare(b.subcategory));
    setItems(newItems);
    setLoading(false);

    try {
      ReactGA.event("view_search_results", {
        search_term: value,
      });
    } catch (e) { }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (name, id, subcategory) => {
    try {
      ReactGA.event("select_item", {
        items: [
          {
            item_id: id,
            item_name: name,
            item_category: subcategory,
          },
        ],
      });
    } catch (e) { }
  };

  let debounceTimer;

  const handleDebounceChange = (event, value) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setLoading(true);
      setSearchQuery(value);
      onSearch(value);
    }, 1000);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!externalOpen) {
          handleClose();
        }
      }}
    >
      <div>
        {externalOpen === undefined && (
          <Button
            onClick={handleOpen}
            style={{
              backgroundColor: "transparent",
            }}
          >
            <Typography color={"#ffffff"}>{translate("search.searchBar")}... &nbsp;</Typography>
            <Iconify icon="eva:search-fill" color={"#ffffff"} />
          </Button>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar isMd={isMd}>
            <Autocomplete
              onChange={(event) => {
                setTimeout(() => {
                  const selectedItem = items.filter(
                    (i) => i.name === event.target.value
                  );
                  if (!selectedItem[0]) {
                    return;
                  }
                  const { id, name, subcategory } = selectedItem[0];
                  const linkTo = PATHS.listings.view(
                    paramCase(subcategory?.toLowerCase()),
                    paramCase(name),
                    paramCase(id)
                  );
                  navigate(linkTo);
                }, 200);
              }}
              loading={loading}
              sx={{
                width: 1,
                height: 1,
              }}
              autoHighlight
              disablePortal
              disableClearable
              popupIcon={null}
              PopperComponent={StyledPopper}
              onInputChange={handleDebounceChange}
              isOptionEqualToValue={(a, b) => {
                return a.id === b.id;
              }}
              noOptionsText={
                <SearchNotFound
                  query={searchQuery}
                  sx={{
                    py: 2,
                  }}
                />
              }
              options={items}
              groupBy={(option) => option.subcategory}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => (
                <InputBase
                  {...params.InputProps}
                  inputProps={params.inputProps}
                  fullWidth
                  autoFocus
                  placeholder={translate("search.searchBar")}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{
                          color: "text.disabled",
                        }}
                      />
                    </InputAdornment>
                  }
                  sx={{
                    height: 1,
                    typography: "h6",
                  }}
                  translate="yes"
                />
              )}
              renderOption={(props, option) => {
                const { name, id, subcategory, image } = option;
                const linkTo = PATHS.listings.view(
                  paramCase(subcategory?.toLowerCase()),
                  paramCase(name),
                  paramCase(id)
                );

                return (
                  <Link
                    color="inherit"
                    to={linkTo}
                    component={RouterLink}
                    key={id}
                    onClick={() => handleClick(name, id, subcategory)}
                  >
                    <Box
                      component="li"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      {...props}
                    >
                      <Image
                        src={image}
                        style={{
                          width: 40,
                          height: 40,
                          objectFit: "cover",
                          marginRight: 10,
                          borderRadius: 5,
                        }}
                      />
                      {name}
                    </Box>
                  </Link>
                );
              }}
            />
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}

export default memo(Searchbar);
