// routes
import { PATHS } from "./routes/paths";

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || "";

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATHS.listings.list;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 78,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 97,
  H_DASHBOARD_DESKTOP_OFFSET: 97 - 24,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
