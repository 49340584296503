import dayjs from "dayjs";
import { GENERAL_CONSTANTS } from "src/config/constants/general-constants";
import { getDateWithoutTimezoneChange } from "./formatTime";
import { EVENT_TYPES_CONSTANTS } from "../config/constants/event-types-constants";
import { RESERVATION_CONSTANTS } from "src/config/constants/reservation-constants";

const formatOutputVariants = ["calendar", "modal"];

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
const formatAreas = (areas, formatOutput) => {
  if (!areas || areas?.length === 0) {
    return [];
  }

  if (!formatOutputVariants.includes(formatOutput)) {
    console.warn(`formatOutput must be one of ${formatOutputVariants}`);
    return [];
  }

  let response = null;

  if (formatOutput === "calendar") {
    const resArray = [];

    areas.forEach((area) => {
      if (area.tables.length === 0) {
        return;
      }

      const tables = area.tables.map((table) => {
        return {
          id: table.id,
          title: `${table.name} (${table.capacity})`,
          capacity: table.capacity,
          area: area.name,
        };
      });

      resArray.push(...tables);
    });

    response = resArray;
  }

  if (formatOutput === "modal") {
    const resObj = {
      areas: [],
      tables: [],
    };

    areas.forEach((area) => {
      if (area.tables.length === 0) {
        return;
      }

      const tables = area.tables.map((table) => {
        return {
          id: table.id,
          title: `${table.name} (${table.capacity})`,
          capacity: table.capacity,
          areaId: area.id,
        };
      });

      resObj.areas.push({
        value: area.id,
        label: area.name,
      });

      resObj.tables.push(...tables);
    });

    response = resObj;
  }

  return response;
};

const formatReservations = (areas) => {
  if (!areas || areas?.length === 0) {
    return [];
  }

  const formattedRes = [];

  areas.forEach((area) => {
    if (!area.tables || area.tables.length === 0) {
      return;
    }

    area.tables.forEach((table) => {
      if (!table.bookedSessions || table.bookedSessions.length === 0) {
        return;
      }
      const tableSessions = [];

      table.bookedSessions.forEach((session) => {
        // const index = formattedRes.findIndex((res) => res.id === session.id);
        // console.log(index);
        // if (index !== -1) {
        //   formattedRes[index] = {
        //     ...formattedRes[index],
        //     table: [...formattedRes[index].table, table.id],
        //   };
        // }

        const startDate = new Date(
          getDateWithoutTimezoneChange(session.bookedHour)
        );

        const endDate = dayjs(startDate)
          .add(session.duration, "minutes")
          .toDate();

        // if (index === -1) {
        tableSessions.push({
          id: session.id,
          persons: session.numberOfGuests,
          name: `${session.organizer?.firstName} ${session.organizer?.lastName}`,
          area: area.id,
          table: table.id,
          tableName: table.name,
          status: session.status,
          startDate: dayjs(startDate).format(),
          endDate: dayjs(endDate).format(),
          email: session.organizer?.account?.email,
          phone: session.organizer?.account?.phone,
          visitsCount: session.visitsCount,
        });
        // }
      });

      formattedRes.push(...tableSessions);
    });
  });

  const groupedReservations = new Map();

  formattedRes.forEach((reservation) => {
    if (!groupedReservations.has(reservation.id)) {
      groupedReservations.set(reservation.id, []);
    }

    groupedReservations.get(reservation.id).push(reservation);
  });

  groupedReservations.forEach((group, id) => {
    const tablesNames = group.map((reservation) => reservation.tableName);

    if (group.length > 1) {
      const tables = group.map((reservation) => reservation.table);

      group.forEach((reservation) => {
        reservation.multipleTables = true;
        reservation.tables = tables;
        reservation.tablesNames = tablesNames;
      });
    }
  });

  console.log(JSON.stringify(Array.from(groupedReservations.values()).flat()))
  return Array.from(groupedReservations.values()).flat();
};

const formatBookingDuration = (duration) => {
  const hours = Math.floor(duration / 60);

  const minutes = duration % 60;

  const seconds = 0;

  // Returns 1:0:0 instead of 01:00:00
  return `${hours}:${minutes}:${seconds}`;
};

const formatBusinessHours = (hours) => {
  if (!hours || hours.length === 0) {
    return [];
  }

  // Helper function to get day index
  const dayIndex = (day) => {
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ].indexOf(day);
  };

  // Initialize the result array and temporary storage for current grouping
  let businessHours = [];
  let currentGroup = null;

  hours.forEach((day) => {
    const index = dayIndex(day.name);
    // Check if we can add the day to the current group
    if (
      currentGroup &&
      currentGroup.startTime === day.openAt &&
      currentGroup.endTime === day.closeAt
    ) {
      currentGroup.daysOfWeek.push(index);
    } else {
      // Finish the current group and start a new one
      if (currentGroup) businessHours.push(currentGroup);
      currentGroup = {
        daysOfWeek: [index],
        startTime: day.openAt,
        // Currently, our hour selectors only go to 23:00, so if we want to close at 00:00, we need to select it, but we should feed it as 24:00 to the calendar
        endTime: day.closeAt === "00:00" ? "24:00" : day.closeAt,
      };
    }
  });

  if (currentGroup) businessHours.push(currentGroup);

  return businessHours;
};

const isWithinBusinessHours = (startDate, endDate, businessHours) => {
  if (!startDate || !endDate || !businessHours || businessHours.length === 0)
    return false;

  // Function to calculate minutes from the start of the day
  const calculateMinutes = (time) =>
    parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);

  // Check each date individually
  const checkDateWithinBusinessHours = (date) => {
    const dayOfWeek = date.getDay(); // Using JavaScript's getDay() method directly
    const currentTimeMinutes = date.getHours() * 60 + date.getMinutes();

    const dayHours = businessHours.find((hours) =>
      hours.daysOfWeek.includes(dayOfWeek)
    );
    if (!dayHours) return false;

    const startTimeMinutes = calculateMinutes(dayHours.startTime);
    const endTimeMinutes =
      dayHours.endTime === "00:00"
        ? 24 * 60
        : calculateMinutes(dayHours.endTime);

    return (
      currentTimeMinutes >= startTimeMinutes &&
      currentTimeMinutes <= endTimeMinutes
    );
  };

  // Adjusting for endDate at 00:00 to be inclusive for the previous day
  if (endDate.getHours() === 0 && endDate.getMinutes() === 0) {
    endDate = new Date(endDate.getTime() - 1); // Adjust by 1 minute to include end of day
  }

  // Check if start and end dates are within business hours
  return (
    checkDateWithinBusinessHours(startDate) &&
    checkDateWithinBusinessHours(endDate)
  );
};

const getSlotWidthBasedOnBookingInterval = (bookingInterval) => {
  return (
    (GENERAL_CONSTANTS.ONE_HOUR_CALENDAR_SLOT_WIDTH * bookingInterval) / 60
  );
};

const isEventNonPermanentAndNoOpeningHours = (
  openingHours,
  startDate,
  endDate,
  isPermanent
) => {
  if (
    startDate !== null &&
    endDate !== null &&
    dayjs(startDate).isBefore(dayjs(endDate)) &&
    openingHours?.length === 0 &&
    isPermanent === false
  ) {
    return EVENT_TYPES_CONSTANTS.COMBINATIONS
      .NO_OPENING_HOURS_AND_NON_PERMANENT;
  }
  if (openingHours?.length > 0 && isPermanent === true) {
    return EVENT_TYPES_CONSTANTS.COMBINATIONS.OPENING_HOURS_AND_PERMANENT;
  }
};

const createOpeningHoursFromStartEndTime = (startDate, endDate) => {
  if (!startDate || !endDate) return [];

  const startDateNoTimezone = getDateWithoutTimezoneChange(startDate);
  const endDateNoTimezone = getDateWithoutTimezoneChange(endDate);

  const startDayOfWeek = dayjs(startDateNoTimezone).day(); // Get day of the week for startDate
  let endDayOfWeek = dayjs(endDateNoTimezone).day(); // Get day of the week for endDate
  const startTime = dayjs(startDate).format("HH:mm"); // Get start time from startDate
  const endTime = dayjs(endDate).format("HH:mm"); // Get end time from endDate

  if (endDayOfWeek < startDayOfWeek) {
    endDayOfWeek += 7; // Add 7 days to endDayOfWeek
  }

  const openingHours = []; // Initialize opening hours array

  for (let i = startDayOfWeek; i <= endDayOfWeek; i++) {
    const day = dayjs().day(i).format(RESERVATION_CONSTANTS.DAY_FORMAT); // Get day name from dayjs
    let openAt = startTime;
    let closeAt = endTime;

    // Adjust openAt and closeAt if it's the first or last day
    if (i === startDayOfWeek) {
      closeAt = "24:00"; // Close at 24:00 on the first day
    }
    if (i === endDayOfWeek) {
      openAt = "00:00"; // Open at 00:00 on the last day
    }

    if (i !== startDayOfWeek && i !== endDayOfWeek) {
      openAt = "00:00"; // Open at 00:00 on all other days
      closeAt = "24:00"; // Close at 24:00 on all other days
    }

    // Push opening hours object to array
    openingHours.push({
      name: day,
      openAt: openAt,
      closeAt: closeAt,
    });
  }

  return openingHours;
};

export {
  formatAreas,
  formatReservations,
  formatBookingDuration,
  formatBusinessHours,
  isWithinBusinessHours,
  getSlotWidthBasedOnBookingInterval,
  isEventNonPermanentAndNoOpeningHours,
  createOpeningHoursFromStartEndTime,
};
