import { gql } from "@apollo/client";

const GET_BOOKING =
  gql(`query BookingUser ($eventId: ID, $bookingSessionId: ID) {
  bookingUser (eventId: $eventId, bookingSessionId: $bookingSessionId) {
    id
    duration createdAt bookedHour bookedDate
    numberOfGuests
    organizer {
      lastName
      username
      firstName
      id
    }
    organizerId
    status
    eventId
    tables {
      area {
        name
      }
      name
    }
  }
}`);

const GET_BOOKINGS_FOR_CALENDAR_BY_ID_AND_DATE =
  gql(`query BookingModule($bookingModuleId: ID!, $date: DateTime) {
    bookingModule(id: $bookingModuleId, date: $date) {
      id
      areas {
        id
        name
        tables {
          capacity
          description
          id
          name
          bookedSessions {
            bookedDate
            id
            bookedHour
            createdAt
            duration
            eventId
            numberOfGuests
            organizer {
              firstName
              account {
                email
                id
                phone
                accountType
              }
              id
              lastName
              username
            }
            organizerId
            status
            visitsCount
          }
        }
      }
      openingHours
      holdingFee
      bookingInterval
      bookingDuration
      event {
        general {
          openingHours
          name
          startDate
          endDate
          isPermanent
          id
          openingHours
        }
        booking {
          bookingInterval
        }
      }
    }
  }`);

const GET_ALL_RESERVATIONS = gql(`
  query BookingModule($bookingModuleId: ID!) {
  bookingModule(id: $bookingModuleId) {
    sessions {
      bookedDate
      bookedHour
      createdAt
      eventId
      id
      duration
      organizer {
        lastName
        firstName
        account {
          phone
          email
        }
      }
      status
      numberOfGuests
      tables {
        area {
          name
          id
        }
        name
        id
        capacity
      }
    }
  }
}
  `);

export {
  GET_BOOKING,
  GET_BOOKINGS_FOR_CALENDAR_BY_ID_AND_DATE,
  GET_ALL_RESERVATIONS,
};
