import { gql } from "@apollo/client";

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($emailOrPhone: String!) {
    forgotPassword(emailOrPhone: $emailOrPhone)
  }
`;

const RESEND_FORGOT_PASSWORD = gql`
  mutation ResendForgotPasswordCode($emailOrPhone: String!) {
    resendForgotPasswordCode(emailOrPhone: $emailOrPhone)
  }
`;

const RESET_PASSWORD_WITH_CODE = gql`
  mutation ResetPasswordWithCode(
    $emailOrPhone: String!
    $code: String!
    $newPassword: String!
  ) {
    resetPasswordWithCode(
      emailOrPhone: $emailOrPhone
      code: $code
      newPassword: $newPassword
    )
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($oldPassword: String!, $newPassword: String!) {
    resetPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

const LOGIN_WITH_SOCIALS = gql`
  mutation BeginSocialLogin($provider: SocialPrvider!) {
    beginSocialLogin(provider: $provider)
  }
`;

const REGISTER_WITH_SOCIALS = gql`
  mutation BeginSocialSignup($provider: SocialPrvider!) {
    beginSocialSignup(provider: $provider)
  }
`;

const LOGIN = gql`
  mutation Login($emailOrPhone: String!, $password: String!) {
    login(emailOrPhone: $emailOrPhone, password: $password)
  }
`;

const REGISTER_WITH_EMAIL = gql`
  mutation RegisterWithEmail($email: String!, $info: RegisterCompleteInfo) {
    registerWithEmail(email: $email, info: $info)
  }
`;

const COMPLETE_REGISTER = gql`
  mutation CompleteRegister(
    $emailOrPhone: String!
    $code: String!
    $info: RegisterCompleteInfo
  ) {
    completeRegister(emailOrPhone: $emailOrPhone, code: $code, info: $info)
  }
`;

const LOGIN_SHADOW_ACCOUNT = gql`
  mutation Mutation {
    shadowLogin
  }
`;

const RESEND_VERIFY_CODE = gql`
  mutation ResendVerifyCode($emailOrPhone: String!) {
    resendVerifyCode(emailOrPhone: $emailOrPhone)
  }
`;

export {
  LOGIN,
  LOGIN_WITH_SOCIALS,
  LOGIN_SHADOW_ACCOUNT,
  REGISTER_WITH_SOCIALS,
  REGISTER_WITH_EMAIL,
  COMPLETE_REGISTER,
  FORGOT_PASSWORD,
  RESEND_FORGOT_PASSWORD,
  RESET_PASSWORD_WITH_CODE,
  RESET_PASSWORD,
  RESEND_VERIFY_CODE,
};
