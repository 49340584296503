import { Box, Button, Divider, Typography, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
// routes
import { PATHS } from "src/routes/paths";
// components
import Iconify from "src/components/iconify/Iconify";
import { useLocales } from "src/locales";
import { SeverErrorIllustration } from "src/assets/illustrations";

const CheckoutFailed = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();

  const handleContinueShopping = () => {
    navigate(PATHS.listings.checkout);
  };

  return (
    <Box sx={{ p: 4, maxWidth: 480, margin: "auto" }}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h4" paragraph>
          {translate("checkout.fail.title")}
        </Typography>

        <SeverErrorIllustration sx={{ height: 260, my: 10 }} />

        <Typography align="center" paragraph>
          {translate("checkout.fail.message1")}
          <br /> <br />
          {translate(`checkout.fail.messageCode_${location?.state?.paymentProviderStatusCode}`, translate("checkout.fail.message2"))}
        </Typography>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Stack>
        <Button
          color="inherit"
          onClick={handleContinueShopping}
          startIcon={<Iconify icon={"eva:arrow-ios-back-fill"} />}
        >
          {translate("checkout.continueShopping")}
        </Button>
      </Stack>
    </Box>
  );
};

export default CheckoutFailed;
