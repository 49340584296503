import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell as TableCellBase,
  Typography as TypographyBase,
  TableContainer,
  Link,
} from "@mui/material";

//
import InvoiceToolbar from "./InvoiceToolbar";
import Image from "src/components/image";
import Label from "src/components/Label";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { fCurrency, formatToTwoDecimals } from "src/utils/formatNumber";
import { fDate } from "src/utils/formatTime";
import { ADMIN_INVOICES_CONSTANTS } from "src/config/constants/admin-invoices";
import { useMemo } from "react";
import { useLocales } from "src/locales";

const Typography = styled(TypographyBase)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

const TableCell = styled(TableCellBase)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

// ----------------------------------------------------------------------

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  "& td": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
};

export default function InvoiceDetails({
  invoice,
  updatePackageStatus,
  showToolbar = true,
}) {
  const { translate } = useLocales();
  const {
    type,
    items,
    taxes,
    invoiceTo,
    createDate,
    totalPrice,
    invoiceFrom,
    subTotalPrice,
    uid,
    dueDate,
  } = invoice;

  const isInvoice = useMemo(
    () => type !== ADMIN_INVOICES_CONSTANTS.invoiceTypes.COMPENSATION_AGREEMENT,
    [type]
  );

  if (!invoice || Object.keys(invoice).length === 0) {
    return null;
  }

 
  return (
    <>
      {showToolbar && (
        <InvoiceToolbar
          invoice={invoice}
          updatePackageStatus={updatePackageStatus}
        />
      )}

      <Card sx={{ pt: 5, px: 5, bgcolor: "#fff" }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Image
              disabledEffect
              alt="logo"
              src="https://nightz-prod.s3.eu-central-1.amazonaws.com/assets/NIGHTZ_LOGO_BLACK.png"
              sx={{ maxWidth: 200 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography variant="h3" textAlign={"right"}>
              {translate(`invoicesTable.formattedType.${type}`)} 
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5, background: "#F2F2F2" }}>
            <Typography variant="h6">Termen Plata: {fDate(dueDate)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mb: 5, background: "#F2F2F2" }}>
            <Typography variant="h6">
              Seria-nr: {uid} din {fDate(createDate)}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography
              paragraph
              variant="overline"
              sx={{
                fontWeight: 700,
              }}
            >
              FURNIZOR:
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {invoiceFrom?.company}
            </Typography>

            <Typography variant="body2">
              CUI: {invoiceFrom.uniqueRegistrationCode}
            </Typography>

            <Typography variant="body2">
              Reg. com.: {invoiceFrom.tradeRegistryNumber}
            </Typography>

            <Typography variant="body2">
              Adresa: {invoiceFrom.street}, {invoiceFrom.city},{" "}
              {invoiceFrom.county}, {invoiceFrom.country}
            </Typography>

            <Typography variant="body2">
              Telefon: {invoiceFrom.phone}
            </Typography>

            <Typography variant="body2">Email: {invoiceFrom.email}</Typography>

            <Typography variant="body2">Banca: {invoiceFrom.bank}</Typography>

            <Typography variant="body2">Cont: {invoiceFrom.iban}</Typography>

            <Typography variant="body2">
              TVA:{" "}
              {invoiceFrom.vatObligations === "VAT" ? "Platitor" : "Neplatitor"}
            </Typography>

            <Typography variant="body2">
              Reprezentant: {invoiceFrom.name}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography
              paragraph
              variant="overline"
              sx={{
                fontWeight: 700,
              }}
            >
              CLIENT:
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {invoiceTo?.company}
            </Typography>

            <Typography variant="body2">
              CUI: {invoiceTo.uniqueRegistrationCode}
            </Typography>

            <Typography variant="body2">
              Reg. com.: {invoiceTo.tradeRegistryNumber}
            </Typography>

            <Typography variant="body2">
              Adresa: {invoiceTo.street}, {invoiceTo.city}, {invoiceTo.county},{" "}
              {invoiceTo.country}
            </Typography>

            <Typography variant="body2">Telefon: {invoiceTo.phone}</Typography>

            <Typography variant="body2">Email: {invoiceTo.email}</Typography>

            <Typography variant="body2">Banca: {invoiceTo.bank}</Typography>

            <Typography variant="body2">Cont: {invoiceTo.iban}</Typography>

            <Typography variant="body2">
              TVA:{" "}
              {invoiceTo.vatObligations === "VAT" ? "Platitor" : "Neplatitor"}
            </Typography>

            <Typography variant="body2">
              Reprezentant: {invoiceTo.name}
            </Typography>
          </Grid>
        </Grid>

        <TableContainer sx={{ overflow: "unset" }}>
          <Scrollbar>
            <Table sx={{ minWidth: 960 }}>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  "& th": { backgroundColor: "transparent" },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>

                  <TableCell align="left">Descriere</TableCell>

                  {isInvoice && (
                    <>
                      <TableCell align="left">U.M. Cant.</TableCell>

                      <TableCell align="right">Preț fără TVA RON</TableCell>
                    </>
                  )}

                  <TableCell align="right">Valoare RON</TableCell>

                  {isInvoice && (
                    <TableCell align="right">Valoare TVA RON</TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {items.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) =>
                        `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>

                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">{row.title}</Typography>

                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                          noWrap
                        >
                          {row.description}
                        </Typography>
                      </Box>
                    </TableCell>

                    {isInvoice && (
                      <>
                        <TableCell align="left">
                          <span
                            style={{
                              marginRight: "12px",
                            }}
                          >
                            buc
                          </span>
                          {row.quantity}
                        </TableCell>

                        <TableCell align="right">
                          {formatToTwoDecimals(fCurrency(Number(row.amount)))}
                        </TableCell>
                      </>
                    )}

                    <TableCell align="right">
                      {formatToTwoDecimals(fCurrency(Number(row.totalAmount)))}
                    </TableCell>

                    {isInvoice && (
                      <TableCell align="right">
                        {taxes &&
                          formatToTwoDecimals(
                            fCurrency(Number(row.taxesAmount))
                          )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}

                <StyledRowResult>
                  <TableCell colSpan={3} />

                  {isInvoice && (
                    <>
                      <TableCell align="right" sx={{ typography: "body1" }}>
                        Total
                      </TableCell>

                      <TableCell
                        align="right"
                        width={120}
                        sx={{ typography: "body1" }}
                      >
                        {formatToTwoDecimals(fCurrency(Number(subTotalPrice)))}
                      </TableCell>

                      <TableCell
                        align="right"
                        width={120}
                        sx={{ typography: "body1" }}
                      >
                        {taxes && formatToTwoDecimals(fCurrency(Number(taxes)))}
                      </TableCell>
                    </>
                  )}
                </StyledRowResult>

                <StyledRowResult>
                  <TableCell colSpan={3} />
                  <TableCell />

                  <TableCell
                    align="right"
                    sx={{
                      typography: "h6",
                      background: "#F2F2F2",
                      width: "150px",
                    }}
                  >
                    Total factură
                  </TableCell>

                  <TableCell
                    align="right"
                    width={140}
                    sx={{ typography: "h6", background: "#F2F2F2" }}
                  >
                    {formatToTwoDecimals(fCurrency(Number(totalPrice)))}
                  </TableCell>
                </StyledRowResult>
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        {!isInvoice && (
          <Grid container>
            <Typography variant="body2" sx={{ mt: 5 }}>
              The parties agree to offset the amounts due and the Provider shall
              transfer to the Beneficiary's account the difference of{" "}
              {fCurrency(totalPrice)} RON.
            </Typography>
            <Grid
              container
              sx={{
                my: 4,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>Signature Provider</Typography>
              <Typography>Signature Beneficiary</Typography>
            </Grid>
          </Grid>
        )}

        <Divider sx={{ mt: 5 }} />

        <Grid container>
          <Grid item xs={12} sx={{ py: 3, textAlign: "center" }}>
            <Typography variant="subtitle2">Have a Question?</Typography>

            <Link href="mailto:contact@nightz.app">
              <Typography variant="body2">contact@nightz.app</Typography>
            </Link>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
