const roles = Object.freeze({
  Admin: ["admin"],
  Company: ["company", "admin"],
  User: ["user", "admin"],
  Shadow: ["shadow", "admin"],
  People: ["user", "shadow"],
  NotAdmin: ["user", "shadow", "company"],
  All: ["user", "shadow", "company", "admin"],
  CompanyOnly: ["company"],
  UserOnly: ["user"],
  ShadowOnly: ["shadow"],
});

export default function getRoles() {
  return roles;
}
