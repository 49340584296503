import PropTypes from "prop-types";
// @mui
import { Box, Typography } from "@mui/material";
// components
import Carousel from "../../../../components/carousel";
import useResponsive from "../../../../hooks/useResponsive";
import ShopEventCard from "./ShopEventCard";
import ShopVenueCard from "./ShopVenueCard";

// ----------------------------------------------------------------------

ShopCardCarousel.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.object,
  autoplay: PropTypes.bool,
  listings: PropTypes.array,
};

export default function ShopCardCarousel({
  title = "",
  sx = {},
  autoplay = true,
  listings = [],
}) {
  const isMobile = useResponsive("down", "md");
  const titleAtPlural = title !== "Trending" ? `${title}s` : title;

  const getLowestPrice = (product) => {
    const tickets = product.tickets.ticketTypes;
    const prices = tickets.map((ticket) => ticket.price);
    return Math.min(...prices);
  };

  const truncateString = (inputString) => {
    const maxLength = 20;
    if (inputString.length <= maxLength) {
      return inputString;
    }
    return inputString.substring(0, maxLength - 3) + "...";
  };

  const mapProducts = (product) => {
    if (product.typeOfCard === "normal")
      return (
        <ShopEventCard
          sx={{ mx: 1 }}
          key={product?.general?.id}
          name={truncateString(product?.general?.name)}
          price={product?.general?.startPrice}
          cover={product?.general?.images[0]}
          id={product?.general?.id}
          subcategory={product?.general?.subCategory?.name}
          entryType={"TICKET"}
        />
      );

    if (product.typeOfCard === "rating")
      return (
        <ShopVenueCard
          sx={{ mx: 1 }}
          rating={product?.rating?.rating}
          reviewsCount={product?.rating?.reviewsCount}
          key={product?.general?.id}
          name={truncateString(product?.general?.name)}
          cover={product?.general?.images[0]}
          id={product?.general?.id}
          subcategory={product?.general?.subCategory?.name}
        />
      );
  };

  if (!listings.length) {
    return;
  }

  if (!isMobile && listings.length < 6) {
    return (
      <Box sx={{ mb: 4, ...sx }}>
        {!!title && (
          <Typography variant="h6" sx={{ ml: 1, mb: 1 }}>
            {titleAtPlural.toUpperCase()}
          </Typography>
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
          }}
        >
          {listings.map(mapProducts)}
        </Box>
      </Box>
    );
  }

  if (isMobile && listings.length < 3) {
    return (
      <Box sx={{ mb: 4, ...sx }}>
        {!!title && (
          <Typography variant="h6" sx={{ ml: 1, mb: 1 }}>
            {titleAtPlural.toUpperCase()}
          </Typography>
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          {listings.map(mapProducts)}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 4, ...sx }}>
      {!!title && (
        <Typography variant="h6" sx={{ ml: 1, mb: 1 }}>
          {titleAtPlural.toUpperCase()}
        </Typography>
      )}
      <Carousel
        arrows={!isMobile}
        autoplay={autoplay}
        slidesToShow={isMobile ? 3 : 6}
        swipeToSlide={true}
        autoplaySpeed={10000}
        style={{ overflow: "show" }}
      >
        {listings.map(mapProducts)}
      </Carousel>
    </Box>
  );
}
