//slice removes the id at the end of the pathname
const comparePathNames = (a, b, sliceTimes = 0) => {
  if (
    a.split("/").slice(0, -sliceTimes).join("/") ===
    b.split("/").slice(0, -sliceTimes).join("/")
  ) {
    return true;
  }
  return false;
};

export { comparePathNames };
