export const EVENT_SUBCATEGORIES_CONTSTANTS = Object.freeze({
  TYPE: {
    EVENT: "Event",
    THEATRE: "Theatre",
    EXPERIENCE: "Experience",
    CONFERENCE: "Conference",
    SPORT_EVENT: "Sport Event",
    RESTAURANT: "Restaurant",
    LANDMARK: "Landmark",
    CUSTOM: "Custom",
    CHILL: "Chill",
  },
  ONLY_FOR_NIGHTZ_ACCOUNT: ["Chill", "Landmark"],
});
