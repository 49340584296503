import { ApolloClient, defaultDataIdFromObject, InMemoryCache } from "@apollo/client";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import { UAParser } from "ua-parser-js";
import { ApolloProvider } from "@apollo/react-hooks";

const dynamicPaths = [
  "/venue/",
  "/event/",
  "/activity/",
  "/chill/",
  "/join/",
];
const androidAppUrl = "market://details?id=app.nightz";
const iphoneAppUrl = "itms-apps://itunes.apple.com/app/id6460032070";

const cache = new InMemoryCache({
  typePolicies: {
    Cart: {
      fields: {
        items: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
      },
    },
  },
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "CartItem":
        return `CartItem:${object.id}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const apollo = new ApolloClient({
  uri: process.env.REACT_APP_API_BASE_URL,
  cache,
});

export default function GlobalServicesProvider({ children }) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);

  //used to redirect to a specific mobile app section when different requests are made from mobile
  useEffect(() => {
    const uaParser = new UAParser();
    const shouldRedirect = dynamicPaths.some((path) =>
      window.location.pathname.startsWith(path)
    );
    if (shouldRedirect) {
       if (uaParser.getOS()?.name?.toLowerCase().includes('android')) {
        window.location.href = androidAppUrl;
      } else if (uaParser.getOS()?.name?.toLowerCase().includes('ios')) {
        window.location.href = iphoneAppUrl;
      } else {
        setTimeout(() => {
          window.location.href = "/download-app";    
        }, 100);
      }
    }
  }, []);
  return <ApolloProvider client={apollo}>{children}</ApolloProvider>;
}

export { cache, apollo };
