// PLEASE MAKE SURE TO UPDATE THE RESERVATION_STATUS TEXT FOR THE WHOLE FILE

export const RESERVATION_CONSTANTS = Object.freeze({
  RESERVATION_STATUS: {
    CONFIRMED: "APPROVED",
    PENDING: "PENDING",
    WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
    CANCEL_BY_VENUE: "CANCEL_BY_VENUE",
    CANCEL_BY_USER: "CANCEL_BY_USER",
    DELETED_BY_VENUE: "DELETED_BY_VENUE",
    DENIED: "DENIED",
    BLOCKED: "BLOCKED",
  },
  RESERVATION_INTERVALS: [30, 60],
  RESERVATION_INTERVAL_DEFAULT: 30,
  RESERVATION_DURATION_OPTIONS: ["60", "90", "120", "150", "180"],
  RESERVATION_DURATION_DEFAULT: [60, 90],
  AREAS_TABLE_ORDER_BY_DEFAULT: "name",
  AREAS_TABLE_ORDER_DEFAULT: "asc",
  SPACES_TABLE_ORDER_BY_DEFAULT: "areaName",
  SPACES_TABLE_ORDER_DEFAULT: "asc",
  HOLDING_FEE_DEFAULT: 0,
  AUTOMATIC_CONFIRMATION_DEFAULT: false,
  CLOSED_FIELD_NAME: "closed",
  OPEN_AT_FIELD_NAME: "openAt",
  CLOSE_AT_FIELD_NAME: "closeAt",
  MODAL_CLOSED_STATE: "CLOSED",
  MODAL_CREATE_MODULE_STATE: "CREATE",
  MODAL_DELETE_MODULE_STATE: "DELETE",
  YEAR_FORMAT: "YYYY-MM-DD",
  DAY_FORMAT: "dddd",
  POLL_INTERVAL_IN_SECONDS: 60,
  RESERVATIONS_TABLE_ORDER_BY_DEFAULT: "status",
  RESERVATIONS_TABLE_ORDER_DEFAULT: "desc",
  FALLBACK_RESERVATION_INTERVAL: 30,
  TIMEOUT_BETWEEN_CHANGE_TEXT: 200,
  BOOKING_CARD_VARIANTS: {
    FULL: 1,
    NON_PERMANENT_EVENT: 2,
  },
  IFRAME_WIDTH: 400,
  IFRAME_HEIGHT: 600,
  IFRAME_THEME: "dark",
});

export const RESERVATIONS_STATUSES_MODAL_CREATE = Object.freeze({
  [RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED]:
    RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED,
  [RESERVATION_CONSTANTS.RESERVATION_STATUS.PENDING]:
    RESERVATION_CONSTANTS.RESERVATION_STATUS.PENDING,
  CANCELLED: RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_VENUE,
});

export const RESERVATION_STATUSES_MODAL_BLOCKED = Object.freeze({
  [RESERVATION_CONSTANTS.RESERVATION_STATUS.BLOCKED]:
    RESERVATION_CONSTANTS.RESERVATION_STATUS.BLOCKED,
});

export const RESERVATION_STATUSES_MODAL_CANCELED = Object.freeze({
  CANCELLED: "CANCEL_BY_VENUE",
});

export const RESERVATION_STATUSES_MODAL_WAITING_FOR_PAYMENT = Object.freeze({
  [RESERVATION_CONSTANTS.RESERVATION_STATUS.WAITING_FOR_PAYMENT]: RESERVATION_CONSTANTS.RESERVATION_STATUS.WAITING_FOR_PAYMENT,
});

export const RESERVATION_STATUSES_MODAL_CONFIRMED = Object.freeze({
  [RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED]:
    RESERVATION_CONSTANTS.RESERVATION_STATUS.CONFIRMED,
  [RESERVATION_CONSTANTS.RESERVATION_STATUS.BLOCKED]:
    RESERVATION_CONSTANTS.RESERVATION_STATUS.BLOCKED,
});
