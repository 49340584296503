import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";

// @mui
import {
  ToggleButton,
  FormLabel,
  ToggleButtonGroup,
  FormControl,
  FormHelperText,
} from "@mui/material";
import merge from "lodash/merge";

// ----------------------------------------------------------------------
//
RHFToggleButtonGroup.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  spacing: PropTypes.number,
  onChange: PropTypes.func,
  helperText: PropTypes.node,
  toggleDisabled: PropTypes.bool,
};

export default function RHFToggleButtonGroup({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  onChange = null,
  toggleDisabled,
  ...other
}) {
  const { control, getValues, setValue } = useFormContext();
  const labelledby = label ? `${name}-${label}` : "";

  const removeInteractivity = (option) => {
    if (getValues(name) === option.value) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event, val, field) => {
    event.stopPropagation();
    const event1 = { target: { value: val } };
    if (onChange) {
      onChange(event1);
    } else {
      field.onChange(event1);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel
              component="legend"
              id={labelledby}
              sx={{ typography: "body2" }}
            >
              {label}
            </FormLabel>
          )}

          <ToggleButtonGroup
            {...field}
            aria-labelledby={labelledby}
            row={row}
            onChange={(ev, val) => handleChange(ev, val, field)}
            disabled={toggleDisabled}
            {...other}
          >
            {options.map((option, index) => (
              <ToggleButton
                key={index}
                value={option.value}
                sx={{
                  pointerEvents: removeInteractivity(option) ? "none" : "auto",
                  textTransform: "none",
                }}
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
