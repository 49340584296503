import { gql } from "@apollo/client";

const GET_VENUES = gql`
  query Venues {
    venues {
      name
      id
      address {
        city
        county
        googleLocationLink
        street
        venueName
      }
      bookLink
      category {
        id
        name
      }
      description
      mainEventCountyVisibility
      images
      phone
      priceRangeEnd
      priceRangeStart
      isPublished
      program
      rating
      reviewsCount
      subCategory {
        id
        name
      }
      tags {
        id
        name
      }
      website
      fields
      organizerDisplayName
      isMainEvent
      status
      venueEmail
      visibility
    }
  }
`;
const GET_VENUES_LIST = gql`
  query Venues {
    venues {
      id
      name
      mainEventCountyVisibility
      isPublished
      address {
        city
        county
        googleLocationLink
        street
        venueName
      }
      category {
        id
        name
        subcategories {
          id
          name
        }
      }
      subCategory {
        id
        name
      }
      images
      program
      status
      rating
      reviewsCount
    }
  }
`;

const GET_VENUE_BY_ID = gql`
  query Venue($venueId: ID!) {
    venue(id: $venueId) {
      address {
        city
        county
        googleLocationLink
        street
        venueName
      }
      bookLink
      category {
        id
        name
      }
      description
      id
      images
      name
      isPublished
      phone
      priceRangeEnd
      priceRangeStart
      program
      rating
      reviewsCount
      subCategory {
        id
        name
      }
      tags {
        id
        name
      }
      mainEventCountyVisibility
      website
      fields
      isMainEvent
      organizerDisplayName
      status
      venueEmail
      visibility
    }
  }
`;

export { GET_VENUES_LIST, GET_VENUE_BY_ID, GET_VENUES };
