import { format, getTime, formatDistanceToNow, fromUnixTime } from "date-fns";
/// todo date-fns ?

// ----------------------------------------------------------------------

export function fDate(date) {
  if (!date) {
    return "";
  }

  let dateRet = null;
  try {
    dateRet = format(new Date(date), "dd MMMM yyyy");
  } catch (e) {
    dateRet = format(fromUnixTime(date), "dd MMMM yyyy");
  }
  return dateRet;
}

export function fDateReverse(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function fDateTime(date) {
  let dateRet = null;
  try {
    dateRet = format(new Date(date), "dd MMM yyyy HH:mm");
  } catch (e) {
    dateRet = format(fromUnixTime(date), "dd MMM yyyy HH:mm");
  }
  return dateRet;
}

export function fTimestamp(date) {
  let dateRet = null;
  try {
    dateRet = getTime(new Date(date));
  } catch (e) {
    dateRet = getTime(fromUnixTime(date));
  }
  return dateRet;
}

export function fTime(date) {
  let dateRet = null;
  try {
    dateRet = format(new Date(date), "HH:mm");
  } catch (e) {
    dateRet = format(fromUnixTime(date), "HH:mm");
  }
  return dateRet;
}

export function fDateTimeSuffix(date) {
  let dateRet = null;
  try {
    dateRet = format(new Date(date), "dd/MM/yyyy hh:mm p");
  } catch (e) {
    dateRet = format(fromUnixTime(date), "dd/MM/yyyy hh:mm p");
  }
  return dateRet;
}

export function fToNow(date) {
  let dateRet = null;
  try {
    dateRet = formatDistanceToNow(new Date(date), {
      addSuffix: true,
    });
  } catch (e) {
    dateRet = formatDistanceToNow(fromUnixTime(date), {
      addSuffix: true,
    });
  }
  return dateRet;
}

export function formatDate(date) {
  const d = new Date(date);
  const day = ("0" + d.getDate()).slice(-2);
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  return `${day}.${month}.${year}`;
}

export const hoursStringsFromObject = (data) => {
  if (!data || !data.openAt || !data.closeAt) return [];

  const { openAt, closeAt } = data;

  const openTime = openAt?.split(":");
  const closeTime = closeAt?.split(":");

  const hours = [];
  for (let i = parseInt(openTime[0]); i <= parseInt(closeTime[0]); i++) {
    hours.push(
      `${String(i).padStart(2, "0")}:${String(openTime[1]).padStart(2, "0")}`
    );
  }

  return hours || [];
};

export function getShortDayNameFromDate(dateInput, locale) {
  let date;

  if (dateInput instanceof Date && !isNaN(dateInput)) {
    date = dateInput;
  } else {
    const parsedDate = new Date(dateInput);
    if (!isNaN(parsedDate)) {
      date = parsedDate;
    } else {
      return "";
    }
  }

  return new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date);
}

export function formatSlotDuration(minutes) {
  if (!minutes || typeof minutes !== "number") {
    return "00:30:00";
  }

  // Validate input to be a multiple of 30
  if (minutes % 30 !== 0) {
    throw new Error("Input must be a multiple of 30.");
  }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const seconds = 0; // Since the input is in minutes and always a multiple of 30, seconds will always be 0

  // Format hours, minutes, and seconds to always show two digits
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  // Construct and return the formatted time string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const getDateWithoutTimezoneChange = (dateString) => {
  const formattedDate = dateString.replace("Z", "").replace("T", " ");

  return new Date(formattedDate);
};
