// @mui
import { enUS, roRO, zhCN } from "@mui/material/locale";

export const allLangs = [
  {
    label: "Romanian",
    value: "ro",
    systemValue: roRO,
    icon: "https://img.icons8.com/color/48/null/romania.png",
  },
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "https://img.icons8.com/color/48/null/great-britain.png",
  },
];

export const defaultLang = allLangs[0]; // English
