export const isToday = (date) =>
  new Date().toDateString() === date.toDateString();

export const isTomorrow = (date) => {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  return tomorrow.toDateString() === date.toDateString();
};

export const isAfterTomorrow = (date) => {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  return date > tomorrow
}

export const isInPast = (string) => {
  if (!string) return false;
  const date = new Date(string);
  const currentDate = new Date();
  return date < currentDate;
};

export function isSameDay(d1Date, d2Date) {
  const d1 = new Date(d1Date);
  const d2 = new Date(d2Date);

  if (!d1Date || !d2Date) return false;

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export const areISOStringsEqual = (isoString1, isoString2) => {
  const date1 = new Date(isoString1);
  const date2 = new Date(isoString2);

  // Compare year, month, day, hour, and minute
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate() &&
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes();
};