import { Box, Stack } from "@mui/material";
import { hideScrollbarY } from "../../utils/cssStyles";
import LocationFilter from "./locationFilter";
import { alpha } from "@mui/material/styles";
import SunSwitch from "../SunSwitch";
import Searchbar from "../../layouts/dashboard/header/Searchbar";

const DesktopFilters = ({
  countyOptions,
  handleChangeCounty,
  county,
  categoryValue,
  handleChangeSwitch,
  categoryOptions,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            mx: "auto",
            px: 0.7,
            ...hideScrollbarY,
            borderRadius: "50px",
            // border: `1.7px solid ${alpha(theme.palette.primary.contrastText, 0.2)}`,
            display: "flex",
            alignItems: "center",
            jusifyContent: "center",
            backgroundColor: alpha("#000000", 0.6),
            // backdropFilter: `blur(4px)`,
            WebkitBackdropFilter: `blur(4px)`,
            height: "48px",
          }}
        >
          {categoryValue && categoryOptions && (
            <SunSwitch
              checked={categoryValue?.id === categoryOptions[0]?.id}
              onChange={handleChangeSwitch}
            />
          )}
          <LocationFilter
            countyOptions={countyOptions}
            handleChangeCounty={handleChangeCounty}
            county={county}
          />
          <div
            style={{
              height: "60%", // Adjust the height according to your layout
              width: "1.8px", // This makes the div look like a thin line
              backgroundColor: "white", // Sets the color of the divider to white
              margin: "20 0px", // Adds horizontal margins (left and right) of 20px
            }}
          />

          <Searchbar />
        </Stack>
      </Box>
    </>
  );
};

export default DesktopFilters;
