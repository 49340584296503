import { gql } from "@apollo/client";

export const GET_UPCOMING_ACTIVITIES = gql`
  query ($upcoming: Boolean) {
    activities(upcoming: $upcoming) {
      event {
        address {
          city
          county
          googleLocationLink
          street
          venueName
        }
        general {
          endDate
          id
          images
          name
          startDate
        }
      }
      bookedSession {
        bookedDate
        bookedHour
        createdAt
        duration
        numberOfGuests
        status
        id
      }
      userWithTickets
    }
  }
`;

export const GET_PAST_ACTIVITIES = gql`
  query Activities($passed: Boolean) {
    activities(passed: $passed) {
      event {
        address {
          city
          county
          googleLocationLink
          street
          venueName
        }
        general {
          endDate
          id
          images
          name
          startDate
        }
      }
      bookedSession {
        bookedDate
        bookedHour
        createdAt
        duration
        numberOfGuests
        status
        id
      }
      userWithTickets
    }
  }
`;
