import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Badge } from "@mui/material";
// routes
import { PATHS } from "../../../routes/paths";
// components
import Iconify from "src/components/iconify/Iconify";
import { GET_CART_QUANTITY } from "src/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useQueryWithAuth } from "src/graphql/instances";

// ----------------------------------------------------------------------

const RootStyle = styled(RouterLink)(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: "flex",
  cursor: "pointer",
  position: "fixed",
  alignItems: "center",
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create("opacity"),
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

export default function CartWidget() {
  const accessToken = localStorage.getItem("accessToken");
  const [totalItems, setTotalItems] = useState(0);
  const { data } = useQueryWithAuth(GET_CART_QUANTITY, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  useEffect(() => {
    if (data) {
      setTotalItems(
        data.cart.items.reduce((sum, item) => sum + item.quantity, 0)
      );
    }
  }, [data]);

  return (
    <RootStyle to={PATHS.listings.checkout}>
      <Badge showZero badgeContent={totalItems} color="error" max={99}>
        <Iconify icon={"eva:shopping-cart-fill"} width={24} height={24} />
      </Badge>
    </RootStyle>
  );
}
