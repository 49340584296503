import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { DocumentNode, TypedDocumentNode } from "graphql";
import {
  QueryHookOptions,
  QueryResult,
  MutationHookOptions,
  MutationTuple,
} from "@apollo/client/react/types/types";

/**
 * @param {DocumentNode | TypedDocumentNode} querry - the querry to be executed
 * @param {QueryHookOptions} options - the options to be passed to the querry execution
 * @returns {QueryResult} - the result of the querry
 * @example const {loading, error, data} = useQuerryWithAuth(GET_EVENTS, { variables: { id: user.id } });
 */
const useQueryWithAuth = (querry, options) => {
  /**
   * retrieves the token from the local storage
   * if the token is not present, the hook will handle the error
   */
  const token = localStorage.getItem("accessToken");

  return useQuery(querry, {
    ...options,
    context: { headers: { Authorization: token } },
  });
};

const useLazyQueryWithAuth = (querry, options) => {
  /**
   * retrieves the token from the local storage
   * if the token is not present, the hook will handle the error
   */
  const token = localStorage.getItem("accessToken");

  return useLazyQuery(querry, {
    ...options,
    context: { headers: { Authorization: token } },
  });
};

/**
 * @param {DocumentNode | TypedDocumentNode} querry - the querry to be executed
 * @param {QueryHookOptions} options - the options to be passed to the querry execution
 * @returns {QueryResult} - the result of the querry
 * @example const {loading, error, data} = useQuerry(GET_EVENTS, { variables: { id: user.id } });
 */
const useQueryCustom = (querry, options) => {
  return useQuery(querry, {
    ...options,
  });
};

/**
 * @param {MutationHookOptions | TypedDocumentNode} mutation - the mutation to be executed
 * @param {MutationHookOptions} options - the options to be passed to the mutation execution
 * @returns {MutationTuple} - the result of the mutation
 * @example const [addEvent, { loading, error, data }] = useMutationWithAuth(ADD_EVENT, { variables: { id: user.id } });
 */
const useMutationWithAuth = (mutation, options) => {
  /**
   * retrieves the token from the local storage
   * if the token is not present, the hook will handle the error
   */
  const token = localStorage.getItem("accessToken");

  return useMutation(mutation, {
    ...options,
    context: {
      headers: {
        Authorization: token,
        "x-nightz-platform": "web",
      },
    },
  });
};

/**
 * @param {MutationHookOptions | TypedDocumentNode} mutation - the mutation to be executed
 * @param {MutationHookOptions} options - the options to be passed to the mutation execution
 * @returns {MutationTuple} - the result of the mutation
 * @example const [addEvent, { loading, error, data }] = useMutationWithAuth(ADD_EVENT, { variables: { id: user.id } });
 */
const useMutationCustom = (mutation, options) => {
  return useMutation(mutation, {
    ...options,
  });
};

export {
  useQueryWithAuth,
  useQueryCustom,
  useMutationWithAuth,
  useMutationCustom,
  useLazyQueryWithAuth,
};
