import { Box } from "@mui/material";
import { RHFTextField } from "src/components/hook-form";
import CheckboxTermsAndConditions from "../../../../components/CheckboxTermsAndConditions";

const BookingCustomerDataCard = ({
  getHeader,
  translate,
  checkboxValue,
  handleCheckbox,
  isInIframe = false,
}) => (
  <Box sx={{ paddingX: 4 }}>
    {getHeader(translate("bookings.contactDetails"), "eva:people-fill")}
    <RHFTextField
      label={translate("bookings.fullName")}
      name="fullName"
      sx={{ marginBottom: 2, marginTop: 2 }}
    />
    <RHFTextField
      label={translate("bookings.phoneNumber")}
      name="phoneNumber"
      sx={{ marginBottom: 2 }}
    />
    <RHFTextField label={translate("bookings.email")} name="email" />
    <CheckboxTermsAndConditions
      checkboxValue={checkboxValue}
      handleCheckbox={handleCheckbox}
      isInIframe={isInIframe}
    />
  </Box>
);

export default BookingCustomerDataCard;
