import PropTypes from "prop-types";
// @mui
import { Paper, Typography } from "@mui/material";
import { useLocales } from "../../locales";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  query: PropTypes.string,
  sx: PropTypes.object,
};

export default function SearchNotFound({ query, sx, ...other }) {
  const { translate } = useLocales();

  return query ? (
    <Paper
      sx={{
        textAlign: "center",
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" paragraph>
        {translate("search.notFound")}
      </Typography>

      <Typography variant="body2">
        {translate("search.notFound")}&nbsp;
        <strong>&quot;{query}&quot;</strong>.
        <br /> {translate("search.tryTypos")}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      {translate("search.enterKeywords")}
    </Typography>
  );
}
