export const GENERAL_CONSTANTS = Object.freeze({
  IOS_APP_STORE_ID: "6460032070",
  USED_CURRENCY: "lei",
  CONTAINER_MAX_WIDTH_EDIT_FORM: "lg",
  ONE_HOUR_CALENDAR_SLOT_WIDTH: 150,
  INPUT_NUMBER_INVALID_CHARACTERS: ["e", "E", "+", "-"],
  INPUT_NUMBER_INVALID_CHARACTERS_ALLOW_MINUS: ["e", "E", "+"],
  NO_NAVIGATION_HISTORY_RETURNED_VALUE: "default",
  DEFAULT_ADMIN_EMAIL: "hello@nightz.app",
});
