import { GENERAL_CONSTANTS } from "./general-constants";

export const META_TAGS = Object.freeze({
  OG_TITLE: "og:title",
  OG_URL: "og:url",
  OG_TYPE: "og:type",
  OG_TYPE_ARTICLE_VALUE: "article",
  OG_DESCRIPTION: "og:description",
  OG_IMAGE: "og:image",
  EVENT_START_TIME: "event:start_time",
  EVENT_END_TIME: "event:end_time",
  EVENT_TIME_DEFAULT: "permanent",
  OG_SITE_NAME: "og:site_name",
  SITE_NAME_VALUE: "Nightz",
  AL_IOS_APP_NAME: "al:ios:app_name",
  IOS_APP_NAME_VALUE: "nightz",
  AL_IOS_APP_STORE_ID: "al:ios:app_store_id",
  IOS_APP_STORE_ID_VALUE: GENERAL_CONSTANTS.IOS_APP_STORE_ID,
  AL_IOS_URL: "al:ios:url",
  IOS_URL_VALUE: `https://apps.apple.com/ro/app/nightz/id${GENERAL_CONSTANTS.IOS_APP_STORE_ID}`,
  TW_CARD: "twitter:card",
  TW_CARD_VALUE: "summary_large_image",
  TW_SITE: "twitter:site",
  TW_SITE_VALUE: "@nightz",
  TW_TITLE: "twitter:title",
  TW_DESCRIPTION: "twitter:description",
  TW_IMAGE: "twitter:image",
  TW_APP_NAME_IPHONE: "twitter:app:name:iphone",
  TW_APP_ID_IPHONE: "twitter:app:id:iphone",
  TW_APP_URL_IPHONE: "twitter:app:url:iphone",
  TW_APP_NAME_GOOGLE: "twitter:app:name:googleplay",
  TW_APP_ID_GOOGLE: "twitter:app:id:googleplay",
  TW_APP_URL_GOOGLE: "twitter:app:url:googleplay",
  TW_APP_URL_GOOGLE_VALUE:
    "https://play.google.com/store/apps/details?id=app.nightz&hl=en&gl=US",
});
