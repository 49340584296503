function PhoneIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6026 14.6674C5.93553 14.66 1.34328 10.0678 1.33594 4.40073C1.33594 2.70705 2.70893 1.33406 4.4026 1.33406C4.57483 1.33275 4.74677 1.34838 4.91594 1.38073C5.07948 1.40493 5.24025 1.44512 5.39594 1.50073C5.61873 1.5789 5.78356 1.76908 5.82927 2.00073L6.7426 6.00073C6.79263 6.22134 6.72736 6.45226 6.56927 6.61406C6.4826 6.70739 6.47594 6.71406 5.65594 7.14073C6.3126 8.5813 7.46474 9.73817 8.9026 10.4007C9.33594 9.57406 9.3426 9.56739 9.43594 9.48073C9.59773 9.32263 9.82866 9.25737 10.0493 9.30739L14.0493 10.2207C14.2733 10.2727 14.4547 10.4365 14.5293 10.6541C14.5855 10.8123 14.6279 10.9751 14.6559 11.1407C14.6828 11.3083 14.6961 11.4777 14.6959 11.6474C14.6703 13.3333 13.2886 14.6823 11.6026 14.6674Z"
        fill="white"
      />
    </svg>
  );
}

export default PhoneIcon;
