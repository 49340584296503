import { FormControl, MenuItem, Select } from "@mui/material";

const LocationFilter = ({ countyOptions, handleChangeCounty, county }) => {
  return (
    <FormControl
      variant="standard"
      sx={{
        minWidth: 120,
        display: "flex",
        alignItems: "center",
        jusifyContent: "center",
      }}
    >
      <Select
        id="county-select-input"
        value={county}
        onChange={handleChangeCounty}
        variant="standard"
        disableUnderline={true}
        sx={{
          backgroundColor: "transparent",
          // Add the following lines to change the icon color
          "& .MuiSelect-icon": {
            color: "white",
          },
        }}
      >
        {countyOptions &&
          countyOptions.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default LocationFilter;
