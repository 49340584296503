import PropTypes from "prop-types";
import { useMemo } from "react";
// @mui
import { Stack, Button, Tooltip, IconButton } from "@mui/material";

import Iconify from "src/components/iconify";
import {
  useLazyQueryWithAuth,
} from "src/graphql/instances";
import {
  DOWNLOAD_INVOICE_PDF, DOWNLOAD_INVOICE_XML,
} from "src/graphql/queries/invoice";
import { ADMIN_INVOICES_CONSTANTS } from "src/config/constants/admin-invoices";
import { useAuthContext } from "src/hooks/useAuthContext";
import getRoles from "src/utils/getRoles";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

InvoiceToolbar.propTypes = {
  invoice: PropTypes.object,
};

export default function InvoiceToolbar({ invoice, updatePackageStatus }) {
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [getInvoicePdfUrl] = useLazyQueryWithAuth(DOWNLOAD_INVOICE_PDF, {
    variables: {
      invoicingDocumentPdfUrlId: invoice.id,
    },
  });

  const [getInvoiceXmlUrl] = useLazyQueryWithAuth(DOWNLOAD_INVOICE_XML, {
    variables: {
      invoicingDocumentXmlUrlId: invoice.id,
    },
  });

  const isInvoicePayed = useMemo(() => {
    return invoice.status === ADMIN_INVOICES_CONSTANTS.statuses.PAID;
  }, [invoice.status]);

  const isUserAdmin = useMemo(() => {
    return getRoles().Admin.includes(user.role);
  }, [user]);

  const downloadInvoicePdf = async () => {
    try {
      const { data, error } = await getInvoicePdfUrl();

      if (error) {
        enqueueSnackbar("Error downloading invoice xml", { variant: "error" });
        console.error("Error downloading invoice xml", error);
        throw error;
      }

      const link = document.createElement("a");
      link.href = data.invoicingDocumentPdfUrl;
      link.download = invoice.id;
      link.click();
      link.remove();
    } catch (error) {
      enqueueSnackbar("Error downloading invoice", { variant: "error" });
      console.log(error);
    }
  };

  const downloadInvoiceXml = async () => {
    const { data, error } = await getInvoiceXmlUrl();

    if (error) {
      enqueueSnackbar("Error downloading invoice xml", { variant: "error" });
      console.error("Error downloading invoice xml", error);
      throw error;
    }

    const link = document.createElement("a");
    link.href = data.invoicingDocumentXmlUrl;
    link.download = invoice.id;
    link.click();
    link.remove();
  };

  const markInvoiceAsPaid = async () => {
    try {
      await updatePackageStatus();
    } catch (error) {
      console.log(error);
    }
  };

  //! May be used for editing invoices
  //   const handleEdit = () => {
  //     navigate(PATHS.invoice.edit(invoice.id));
  //   };

  return (
    <>
      <Stack
        spacing={2}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ sm: "center" }}
        sx={{ mb: 5 }}
      >
        <Stack direction="row" spacing={1}>
          {/* <Tooltip title="Edit">
            <IconButton onClick={handleEdit}>
              <Iconify icon="eva:edit-fill" />
            </IconButton>
          </Tooltip> */}

          {/*<Tooltip title="View">*/}
          {/*  <IconButton onClick={viewInvoice}>*/}
          {/*    <Iconify icon="eva:eye-fill" />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}

          <Tooltip title="Download pdf">
            <IconButton onClick={downloadInvoicePdf}>
              <Iconify icon="eva:download-fill" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Download xml">
            <IconButton onClick={downloadInvoiceXml}>
              <Iconify icon="carbon:xml" />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title="Print">
            <IconButton>
              <Iconify icon="eva:printer-fill" />
            </IconButton>
          </Tooltip> */}

          {/* <Tooltip title="Send">
            <IconButton>
              <Iconify icon="ic:round-send" />
            </IconButton>
          </Tooltip> */}

          {/* <Tooltip title="Share">
            <IconButton>
              <Iconify icon="eva:share-fill" />
            </IconButton>
          </Tooltip> */}
        </Stack>

        {!isInvoicePayed && isUserAdmin && (
          <Button
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="eva:checkmark-fill" />}
            sx={{ alignSelf: "flex-end" }}
            onClick={markInvoiceAsPaid}
          >
            Mark as Paid
          </Button>
        )}
      </Stack>
    </>
  );
}
