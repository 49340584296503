import { gql } from "@apollo/client";

const START_ADD_PAYMENT_METHOD = gql`
  mutation StartAddPaymentMethod {
    startAddPaymentMethod
  }
`;

const COMPLETE_ADD_PAYMENT_METHOD = gql`
  mutation CompleteAddPaymentMethod($orderId: String!) {
    completeAddPaymentMethod(orderId: $orderId) {
      id
      maskedPan
      expiration
    }
  }
`;

const UPDATE_BILLING_INFO = gql`
  mutation UpdateBillingInfo($input: BillingInfoArgs!) {
    updateBillingInfo(input: $input) {
      id
      firstName
      lastName
      address
      country
      countryId
      state
      stateId
      city
      cityId
      postCode
    }
  }
`;

const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod($removePaymentMethodId: ID!) {
    removePaymentMethod(id: $removePaymentMethodId)
  }
`;

export {
  START_ADD_PAYMENT_METHOD,
  UPDATE_BILLING_INFO,
  REMOVE_PAYMENT_METHOD,
  COMPLETE_ADD_PAYMENT_METHOD,
};
