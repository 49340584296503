import { gql } from "@apollo/client";

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $username: String
    $firstName: String
    $lastName: String
    $avatar: String
  ) {
    updateUserProfile(
      username: $username
      firstName: $firstName
      lastName: $lastName
      avatar: $avatar
    ) {
      avatar
      firstName
      lastName
      username
      id
    }
  }
`;

const UPDATE_COMPANY_PROFILE = gql`
  mutation UpdateCompanyProfile(
    $firstName: String
    $lastName: String
    $avatar: String!
  ) {
    updateCompanyProfile(
      firstName: $firstName
      lastName: $lastName
      avatar: $avatar
    ) {
      id
      firstName
      lastName
      avatar
      comission
      approval
    }
  }
`;

const UPDATE_ADMIN_PROFILE = gql`
  mutation UpdateAdminProfile($name: String!) {
    updateAdminProfile(name: $name) {
      id
      name
    }
  }
`;

const UPDATE_PROFILE_MEDIA = gql`
  mutation UpdateProfileMedia {
    updateProfileMedia {
      id
      uploadUrl
      publicUrl
    }
  }
`;

const CONFIRM_PROFILE_MEDIA = gql`
  mutation ConfirmProfileMedia($id: String!) {
    confirmProfileMedia(id: $id)
  }
`;

const UPDATE_SHADOW_ACCOUNT_INFO = gql`
  mutation UpdateAccountInfo($email: String, $phone: String) {
    updateAccountInfo(email: $email, phone: $phone) {
      email
      id
      phone
    }
  }
`;

const UPDATE_SHADOW_PROFILE = gql`
  mutation UpdateUserProfile(
    $firstName: String
    $phone: String
    $lastName: String
  ) {
    updateUserProfile(
      firstName: $firstName
      phone: $phone
      lastName: $lastName
    ) {
      id
    }
  }
`;

export {
  UPDATE_USER_PROFILE,
  UPDATE_ADMIN_PROFILE,
  UPDATE_COMPANY_PROFILE,
  UPDATE_PROFILE_MEDIA,
  CONFIRM_PROFILE_MEDIA,
  UPDATE_SHADOW_ACCOUNT_INFO,
  UPDATE_SHADOW_PROFILE,
};
