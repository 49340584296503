import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
// routes
import { PATH_AUTH, PATHS } from "../../../routes/paths";
// auth
import { useAuthContext } from "../../../hooks/useAuthContext";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import { useSnackbar } from "notistack";
import { useLocales } from "../../../locales";
import getRoles from "../../../utils/getRoles";
import SvgColor from "../../../components/svg-color";
import useResponsive from "../../../hooks/useResponsive";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout, isAuthenticated } = useAuthContext();

  const theme = useTheme();

  const location = useLocation();

  const { translate, currentLang } = useLocales();

  const isMobileDesignVersion = useResponsive("down", "sm");

  const OPTIONS_BASIC = [
    {
      label: translate("accountMenu.home"),
      linkTo: PATHS.listings.list,
    },
  ];

  const [options, setOptions] = useState(OPTIONS_BASIC);

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const OPTIONS_USER = [
    ...OPTIONS_BASIC,
    {
      label: translate("accountMenu.profile"),
      linkTo: PATHS.user.account,
    },
    {
      label: translate("accountMenu.myCart"),
      linkTo: PATHS.listings.checkout,
    },
    {
      label: translate("accountMenu.tickets"),
      linkTo: PATHS.user.tickets,
    },
  ];
  const OPTIONS_COMPANY = [
    ...OPTIONS_BASIC,
    {
      label: translate("accountMenu.myDashboard"),
      linkTo: PATHS.dashboard.root,
    },
  ];
  const OPTIONS_ADMIN = [
    ...OPTIONS_BASIC,
    {
      label: translate("accountMenu.myDashboard"),
      linkTo: PATHS.dashboard.root,
    },
  ];

  useEffect(() => {
    if (user.role === "user") {
      setOptions(OPTIONS_USER);
    }
    if (
      getRoles().Company.includes(user.role) &&
      location.pathname.split("/").includes("listings")
    ) {
      setOptions(OPTIONS_COMPANY);
    }
    if (
      getRoles().Company.includes(user.role) &&
      !location.pathname.split("/").includes("listings")
    ) {
      setOptions(OPTIONS_BASIC);
    }
  }, [user, location, currentLang]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      {isMobileDesignVersion && (
        <Box onClick={handleOpenPopover}>
          <CustomAvatar
            src={user?.avatar}
            alt={user?.firstName}
            name={user?.firstName}
          />
        </Box>
      )}
      {!isMobileDesignVersion && (
        <IconButtonAnimate
          onClick={handleOpenPopover}
          sx={{
            p: 0.5,
            ...(openPopover && {
              "&:before": {
                zIndex: 1,
                content: "''",
                width: "100%",
                height: "100%",
                borderRadius: "50px",
                position: "absolute",
                bgcolor: (theme) => alpha(theme.palette.grey[800], 0.2),
              },
            }),
            height: "48px",
            backgroundColor: alpha("#000000", 0.6),
            // backdropFilter: `blur(4px)`,
            WebkitBackdropFilter: `blur(4px)`,
            borderRadius: isMobileDesignVersion ? null : "50px",
            // border: isMobileDesignVersion ? null : `1.7px solid ${alpha(theme.palette.primary.contrastText, 0.2)}`,
          }}
        >
          <SvgColor
            src="/assets/icons/navbar/ic_menu.svg"
            sx={{ mx: 1, bgcolor: theme.palette.grey[100] }}
          />
          <CustomAvatar
            src={user?.avatar}
            alt={user?.firstName}
            name={user?.firstName}
          />
        </IconButtonAnimate>
      )}

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
        disableScrollLock
        closeOnMouseLeave={true}
      >
        {!isAuthenticated && (
          <Box sx={{ my: 1.5, px: 2.5, mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              {translate("accountMenu.message")}
            </Typography>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Button onClick={() => navigate(PATH_AUTH.login)}>
              {translate("accountMenu.login")}
            </Button>
            <Button onClick={() => navigate(PATH_AUTH.register)}>
              {translate("accountMenu.register")}
            </Button>
          </Box>
        )}
        {isAuthenticated && (
          <Box>
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle2" noWrap>
                {user?.displayName}
              </Typography>

              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {user?.email}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: "dashed" }} />

            <Stack sx={{ p: 1 }}>
              {options.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => handleClickItem(option.linkTo)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Stack>

            <Divider sx={{ borderStyle: "dashed" }} />

            <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
              {translate("accountMenu.logout")}
            </MenuItem>
          </Box>
        )}
      </MenuPopover>
    </>
  );
}
