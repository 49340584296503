import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// @mui
import { Box } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import { useSettingsContext } from "../../components/settings";
//
import Main from "./Main";
import Header from "./header";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";
import MainFooter from "../main/MainFooter";
import { useAuthContext } from "../../hooks/useAuthContext";

// ----------------------------------------------------------------------

const HORIZONTAL_ONLY_PAGES = ["listings", "booking"];

export default function DashboardLayout() {
  const { themeMode, onChangeLayout } = useSettingsContext();
  const { user } = useAuthContext();

  const isDesktop = useResponsive("up", "lg");

  const [open, setOpen] = useState(false);

  const [layout, setLayout] = useState();

  const isNavHorizontal = layout === "horizontal";

  const location = useLocation().pathname.split("/");

  const isNavMini = layout === "mini";

  useEffect(() => {
    if (HORIZONTAL_ONLY_PAGES.includes(location[1]) || user.role === "user") {
      setLayout("horizontal");
    } else {
      setLayout("vertical");
    }
  }, [location, user]);

  useEffect(() => {
    if (layout === "vertical" || layout === "horizontal") {
      onChangeLayout({ target: { value: layout } });
    }
  }, [layout]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = (
    <NavVertical openNav={open} onCloseNav={handleClose} />
  );

  if (
    HORIZONTAL_ONLY_PAGES.includes(location[1]) &&
    location[2] === "details"
  ) {
    return (
      <>
        <Box
          component="main"
          sx={{
            backgroundColor: themeMode === "light" ? "#fff" : "#000",
            pt: 12,
            pb: 10,
          }}
        >
          <Outlet />
        </Box>
        <MainFooter isCustomDesign isLightTheme={themeMode === "light"} />
      </>
    );
  }

  if (isNavHorizontal) {
    return (
      <>
        {location[1] === "listings" && location[2] === "list" ||
          location[1] === "booking" && location[2]?.startsWith("new") ? null : (
          <Header
            onOpenNav={handleOpen}
            displayLogo={true}
            themeLayout={layout}
          />
        )}
        <Box>
          {/*{isDesktop ? null : renderNavVertical}*/}
          <Main>
            <Outlet />
          </Main>
        </Box>
        <MainFooter />
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} themeLayout={layout} />

        <Box
          sx={{
            display: { lg: "flex" },
            minHeight: { lg: 1 },
          }}
        >
          {location[1] !== "listings" && isDesktop ? <NavMini /> : null}
          <Main>
            <Outlet />
          </Main>
        </Box>
        <MainFooter />
      </>
    );
  }
  return (
    <>
      <Header onOpenNav={handleOpen} themeLayout={layout} />

      <Box
        sx={{
          display: { lg: "flex" },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}
        <Main>
          <Outlet />
        </Main>
      </Box>
      <MainFooter />
    </>
  );
}
