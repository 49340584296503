import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { PATH_PAGE } from "../../routes/paths";
import { useLazyQuery } from "@apollo/client";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { GET_EVENT } from "../../graphql/queries";
import EventImageLayout from "../../layouts/event";
import { BookingDetailsCard } from "../../sections/dashboard/events/listing-details";
import { GET_BOOKING } from "../../graphql/queries/bookings";
import { SET_BOOKING_STATUS } from "../../graphql/mutations";
import { RESERVATION_CONSTANTS } from "../../config/constants/reservation-constants";
import { useSnackbar } from "notistack";
import { useLocales } from "../../locales";
import { useMutationWithAuth } from "../../graphql/instances";

function BookingDetails() {
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const { bookingId = "" } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const [getEvent] = useLazyQuery(GET_EVENT, {
    context: { headers: { Authorization: token } },
  });

  const [getBooking] = useLazyQuery(GET_BOOKING, {
    context: { headers: { Authorization: token } },
    variables: { bookingSessionId: bookingId },
    fetchPolicy: "network-only",
  });

  const [setBookingStatus] = useMutationWithAuth(SET_BOOKING_STATUS);

  const cancelBooking = async () => {
    try {
      const res = await setBookingStatus({
        variables: {
          bookingSessionId: bookingId,
          status: RESERVATION_CONSTANTS.RESERVATION_STATUS.CANCEL_BY_USER,
        },
      });
      if (res.data) {
        enqueueSnackbar(translate("bookings.successCancel"), {
          variant: "success",
        });
        getData();
      }
    } catch (e) {
      enqueueSnackbar(translate("bookings.cancelError"), {
        variant: "error",
      });
    }
  };

  const getData = async () => {
    try {
      const bookingResponse = await getBooking();
      const bookingUser = bookingResponse.data?.bookingUser[0];
      const eventResponse = await getEvent({
        variables: { eventId: bookingUser?.eventId },
      });
      const event = eventResponse.data?.event;

      if (!event || !bookingUser) {
        navigate(`${PATH_PAGE.pageResourceNotAvailable}?redirectPath=${pathname}`);
      }

      setEventData(event);
      setBookingData(bookingUser);
      setLoading(false);
    } catch (error) {
      console.error(error);
      navigate(PATH_PAGE.page404);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) return <LoadingScreen />;

  return (
    <EventImageLayout
      title={`Booking Details ${eventData?.general?.name}`}
      withBackButton
      image={eventData?.general?.images[0]}
      organizerDisplayName={eventData?.general?.organizerDisplayName}
      organizerAvatar={eventData?.general?.company?.avatar}
      fields={eventData?.general?.fields}
    >
      {!!eventData && !!bookingData && (
        <BookingDetailsCard
          eventData={eventData}
          bookingData={bookingData}
          cancelBooking={cancelBooking}
        />
      )}
    </EventImageLayout>
  );
}

export default BookingDetails;
