// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
//

export const defaultSettings = {
  themeMode: "dark",
  themeDirection: "ltr",
  themeContrast: "bold",
  themeLayout: "vertical",
  themeColorPresets: "blue",
  themeStretch: false,
};
