import Chip, { chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const RectangleChip = styled(Chip)(({ theme }) => ({
  borderRadius: 6,
  [`& .${chipClasses.label}`]: {
    fontWeight: "bold",
  },
}));

export default RectangleChip;
