export const ADMIN_INVOICES_CONSTANTS = Object.freeze({
  statuses: {
    INVOICED: "INVOICED",
    PAID: "PAID",
  },
  invoiceTypes: {
    COMPENSATION_AGREEMENT: "COMPENSATION_AGREEMENT",
    INVOICE: "INVOICE",
    AUTOINVOICE: "AUTO_INVOICE",
  },
  adminTableHead: [
    { id: "companyName", label: "invoicesTable.companyName" },
    {
      id: "amount",
      label: "Amount",
    },
    {
      id: "content",
      label: "invoicesTable.content",
    },
    {
      id: "created",
      label: "invoicesTable.created",
    },
    {
      id: "status",
      label: "invoicesTable.status",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  companyTableHead: [
    { id: "documentName", label: "invoicesTable.documentName" },
    {
      id: "amount",
      label: "invoicesTable.amount",
    },
    {
      id: "type",
      label: "invoicesTable.type",
    },
    {
      id: "created",
      label: "invoicesTable.created",
    },
    {
      id: "company",
      label: "invoicesTable.company",
    },
    {
      id: "status",
      label: "invoicesTable.status",
    },
    {
      id: "actions",
      label: "",
    },
  ],
});
