import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Box } from "@mui/material";
// components
import Image from "../../../../components/image";
import Lightbox from "../../../../components/lightbox";
import useResponsive from "../../../../hooks/useResponsive";
import { IMAGES_CONSTANTS } from "../../../../config/constants/images-constants";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

// ----------------------------------------------------------------------

const THUMB_SIZE = 192;

DetailsGallery.propTypes = {
  images: PropTypes.array,
};

export default function DetailsGallery({ images }) {
  const theme = useTheme();

  const [selectedImage, setSelectedImage] = useState(-1);
  const [imagesHaveApiCompression, setImagesHaveApiCompression] =
    useState(true);
  const imagesLightbox = images.map((img) => ({ src: img }));
  const isMobile = useResponsive("down", "md");

  const handleOpenLightbox = (imageUrl) => {
    const imageIndex = imagesLightbox.findIndex(
      (image) => image.src === imageUrl
    );
    setSelectedImage(imageIndex);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  const renderThumbnails = isMobile ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      {images.map((img, index) => (
        <Image
          key={`${img}@${IMAGES_CONSTANTS.THUMBNAIL_SIZE_MOBILE}`}
          ratio={IMAGES_CONSTANTS.LISTING_IMAGE_RATIO}
          disabledEffect
          alt="thumbnail"
          src={`${img}${
            imagesHaveApiCompression
              ? `@${IMAGES_CONSTANTS.THUMBNAIL_SIZE_MOBILE}`
              : ""
          }`}
          onClick={() => {
            handleOpenLightbox(img);
          }}
          onError={(currentTarget) => {
            setImagesHaveApiCompression(false);
          }}
          sx={{
            mb: 2,
            borderRadius: 1.5,
            cursor: "pointer",
          }}
        />
      ))}
    </Box>
  ) : (
    <Grid container spacing={2}>
      {images.map((img, index) => (
        <Grid item key={index}>
          <Image
            key={`${img}@${IMAGES_CONSTANTS.THUMBNAIL_SIZE_WEB}`}
            ratio={IMAGES_CONSTANTS.LISTING_IMAGE_RATIO}
            disabledEffect
            alt="thumbnail"
            src={`${img}${
              imagesHaveApiCompression
                ? `@${IMAGES_CONSTANTS.THUMBNAIL_SIZE_WEB}`
                : ""
            }`}
            onClick={() => {
              handleOpenLightbox(img);
            }}
            onError={(currentTarget) => {
              setImagesHaveApiCompression(false);
            }}
            sx={{
              width: THUMB_SIZE,
              height: "auto",
              borderRadius: 1.5,
              cursor: "pointer",
            }}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <Box
        sx={{
          "& .slick-slide": {
            float: theme.direction === "rtl" ? "right" : "left",
          },
        }}
      >
        {renderThumbnails}
      </Box>

      <Lightbox
        index={selectedImage}
        slides={imagesLightbox}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
        plugins={[Fullscreen]}
      />
    </>
  );
}
