import { gql } from "@apollo/client";

const UPDATE_COMPANY_INFO = gql`
  mutation UpdateCompanyInfo($companyInfo: CompanyInfoUpdate!) {
    updateCompanyInfo(companyInfo: $companyInfo) {
      id
    }
  }
`;
const UPDATE_COMPANY_INFO_STATUS = gql`
  mutation UpdateCompanyInfo(
    $approval: CompanyApprovalStatusUpdate
    $companyInfo: CompanyInfoUpdate!
  ) {
    updateCompanyInfo(approval: $approval, companyInfo: $companyInfo) {
      id
    }
  }
`;

const ADMIN_UPDATE_COMPANY_INFO = gql`
  mutation AdminUpdateCompanyProfile(
    $adminUpdateCompanyProfileId: ID!
    $approval: CompanyApprovalStatus
    $comission: Float
    $invoiceSeries: String
    $invoiceNumber: Float
    $companyInfo: CompanyInfoUpdate
  ) {
    adminUpdateCompanyProfile(
      id: $adminUpdateCompanyProfileId
      approval: $approval
      comission: $comission
      invoiceSeries: $invoiceSeries
      invoiceNumber: $invoiceNumber
      companyInfo: $companyInfo
    ) {
      approval
      comission
    }
  }
`;

const GET_COMPANIES_INFO = gql`
  query CompanyInfo {
    companies {
      companyInfo {
        companyName
      }
      id
    }
  }
`;

export {
  UPDATE_COMPANY_INFO,
  ADMIN_UPDATE_COMPANY_INFO,
  UPDATE_COMPANY_INFO_STATUS,
  GET_COMPANIES_INFO,
};
