import { Stack, Typography } from "@mui/material";
import { fTime } from "../../../../utils/formatTime";
import PropTypes from "prop-types";

const GenericShowcaseSummaryDetails = ({ general, address }) => {
  const getMonth = (date) =>
    new Date(date).toLocaleString("en-US", { month: "short" }).toUpperCase();

  const getDay = (date) => new Date(date).getDate();

  return (
    <>
      <Stack
        direction="column"
        sx={{ borderRight: "2px solid white", paddingRight: 1 }}
      >
        <Typography
          variant="h6"
          align="center"
          lineHeight={0.8}
          paddingBottom={1}
        >
          {getMonth(general.startDate)}
        </Typography>
        <Typography align="center" fontSize={26} lineHeight={0.8}>
          {getDay(general.startDate)}
        </Typography>
      </Stack>
      <Stack direction="column">
        <Typography
          sx={{ cursor: "pointer" }}
          variant="h6"
          align="left"
          lineHeight={0.8}
          paddingBottom={1}
          onClick={() => {
            window.open(address.googleLocationLink);
          }}
        >
          {address.street && address.street.toUpperCase()}
        </Typography>
        <Typography align="left" fontSize={20} lineHeight={1}>
          {`${fTime(general.startDate)} - ${fTime(general.endDate)}`}
        </Typography>
      </Stack>
    </>
  );
};

GenericShowcaseSummaryDetails.propTypes = {
  general: PropTypes.object,
  address: PropTypes.object,
};
export default GenericShowcaseSummaryDetails;
