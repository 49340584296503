import PropTypes from "prop-types";
import { Card, CardContent, Typography } from "@mui/material";
import { fCurrency } from "../../../../utils/formatNumber";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { useLocales } from "../../../../locales";
import Markdown from "../../../../components/Markdown";

ProductCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
};

export default function ProductCard({ name, description, price, children }) {
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <Card
      raised
      sx={{
        mt: 2,
        backgroundColor: theme.palette.grey[800],
        borderRadius: "8px",
      }}
    >
      <CardContent
        sx={{
          p: 2,
          pb: "0 !important",
          display: "flex",
          justifyContent: "space-between",
          minHeight: 100,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{name}</Typography>
          {description && <Markdown children={description} />}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="subtitle1"
            align="right"
            component={"div"}
            sx={{
              pb: 1,
            }}
          >
            {`${fCurrency(price)} lei`}
          </Typography>

          {children}
        </div>
      </CardContent>
    </Card>
  );
}
